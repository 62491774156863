import React from "react";
import PropTypes from "prop-types";

const SplitArrow = ({ fill = "var(--light-navy)", height = "9", width = "10" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0878 11.9484L12.4274 10.2954C12.6309 10.1556 12.6309 9.81225 12.4274 9.67238L10.0878 8.00671C9.97338 7.93044 9.82073 7.91766 9.69365 7.98126C9.56646 8.04485 9.49018 8.17195 9.49018 8.32459L9.49027 9.4181L7.63381 9.4181C7.48125 9.4181 7.32862 9.34182 7.22688 9.21464L5.16704 6.49358L7.22688 3.77253C7.32861 3.64534 7.46849 3.56907 7.63381 3.56907L9.49027 3.56907L9.49027 4.66258C9.49027 4.80245 9.56655 4.92964 9.69374 5.00591C9.82092 5.0695 9.97347 5.05673 10.0879 4.98045L12.4275 3.31479C12.6309 3.17491 12.6309 2.83159 12.4275 2.69172L10.0879 1.03881C9.97347 0.962534 9.82082 0.949762 9.69374 1.01336C9.56655 1.07695 9.49027 1.20405 9.49027 1.35669L9.49027 2.4502L7.63381 2.4502C7.12525 2.4502 6.62935 2.69181 6.32414 3.09865L4.18804 5.93414L1.07281 5.93414C0.754934 5.93414 0.500653 6.18842 0.500653 6.5063C0.500653 6.82418 0.754934 7.07846 1.07281 7.07846L4.17532 7.07855L6.31143 9.91403C6.61662 10.321 7.11251 10.5625 7.62109 10.5625L9.49032 10.5625L9.49032 11.656C9.49032 11.7959 9.5666 11.9231 9.69378 11.9993C9.82088 12.0501 9.97338 12.0374 10.0878 11.9484Z"
        fill={fill}
        stroke="white"
      />
    </svg>
  );
};

SplitArrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default SplitArrow;
