import React from "react";
import { Layout, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import styles from "./AmbassadorPageContainer.module.less";
import selectors from "../../../../rdx/selectors";
import actions from "../../../../rdx/actions";

const { Header, Content, Footer } = Layout;

type TabT = {
  key: string;
  tab: React.ReactNode;
  forceRender: boolean;
  className?: string;
};

type AmbassadorPageContainerT = {
  headerContent: React.ReactNode;
  footerContent: React.ReactNode;
  tabs: Array<TabT>;
  tabsClassName: string;
  activeTabKey: string;
};

function AmbassadorPageContainer(props: AmbassadorPageContainerT) {
  const { headerContent, footerContent, tabs, tabsClassName, activeTabKey } = props;
  const alertMessage = useSelector(selectors.selectAlertMessage);
  const dispatch = useDispatch();

  const closeAlertMessage = () => {
    dispatch(actions.clearAlertMessage());
  };

  return (
    <Layout className={styles.pageContainer}>
      <div className={styles.bg} />
      <Content className={styles.contentContainer}>
        <Snackbar
          open={!!alertMessage.visible && !!alertMessage.message}
          autoHideDuration={alertMessage.duration}
          onClose={closeAlertMessage}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={closeAlertMessage} severity={alertMessage.severity}>
            {alertMessage.message}
          </Alert>
        </Snackbar>
        <Layout className={styles.contentLayout}>
          <Header className={styles.header}>
            <div className={styles.logo} />
            <div className={styles.headerContent}>{headerContent}</div>
          </Header>
          <Content className={styles.content}>
            <Tabs
              className={[styles.tabWindow, tabsClassName].join(" ")}
              activeKey={activeTabKey}
              renderTabBar={() => <></>}
              destroyInactiveTabPane
              items={tabs.map(({ key, tab, forceRender, className }) => ({
                key,
                label: "",
                children: tab,
                forceRender,
                className: [styles.tab, className].join(" "),
              }))}
            />
          </Content>
          <Footer className={styles.footer}>{footerContent}</Footer>
        </Layout>
      </Content>
    </Layout>
  );
}

export default AmbassadorPageContainer;
