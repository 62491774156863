import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { Layout } from "antd";
import { HeaderLogoBlue } from "components/Icons";
import { useMediaQuery } from "@mui/material";
import { theme } from "styles/themes";
import Spinner from "components/LoadingElements/Spinner";
import styles from "./styles.module.less";

export default function LoadingScreen() {
  // When prod is down, timer will set a falback state displaying fallback img.
  useEffectOnce(() => {
    const timer = setTimeout(() => {
      setFallbackState(true);
    }, 4000);
    return () => clearTimeout(timer);
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [fallbackState, setFallbackState] = useState<boolean>(false);

  const YouSpinMeRightRound = () => (
    <div className={styles.container} data-test-id="LoadingScreen">
      <div className={styles.logo}>
        <HeaderLogoBlue width={152} />
      </div>
      <Spinner loading />
    </div>
  );

  const getSizedStyle = () => {
    if (isMobile) return styles.isMobile;
    if (isTablet) return styles.isTablet;
    return styles.isDesktop;
  };

  const Fallback = () => {
    return <div className={getSizedStyle()} data-test-id="FallbackScreen" />;
  };

  return (
    <Layout className={styles.layout}>
      <Layout.Content className={fallbackState ? styles.fallbackContent : styles.content}>
        {fallbackState ? <Fallback /> : <YouSpinMeRightRound />}
      </Layout.Content>
    </Layout>
  );
}
