export enum ProjectEligibilities {
  SETTER_SALES_PRO = "Setter / Sales Pro",
  CLOSER = "Closer",
  TEAM_BUILDER = "Team Builder",
  ORG_STAFF = "Org Staff",
}

export enum UserAutocompletePathEnum {
  SETTER = "project_consultants",
  CLOSER = "project_mentors",
  AMBASSADOR = "project_ambassadors",
  MANAGER = "project_coordinators",
  LEAD_GENERATOR = "project_lead_generators",
  ROOFER = "roofers",
}
