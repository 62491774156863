import React from "react";
import PropTypes from "prop-types";

const InstalledThisMonthIcon = ({ width = "30", height = "26", fill = "var(--eggplant)" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9738 11.6157L16.1377 13.4657H18.5656L18.077 11.6157H15.9738ZM15.9738 11.6157H13.6264L13.4625 13.4657H16.1347L16.3492 15.9114H13.251L13.0246 18.4793H16.5756L16.3522 15.9114L15.9738 11.6157ZM19.0601 11.568L19.5367 11.2225L18.074 10.153L18.6371 8.41625H16.8228L16.2628 6.69141L14.7971 7.76087L13.3315 6.69141L12.7744 8.41625H10.9602L11.5232 10.15L10.0575 11.2165L10.5342 11.5651M16.656 19.376L16.7424 20.3591L18.7532 20.7612L18.8486 21.3064H10.7487L10.844 20.7612L12.8578 20.3591L12.9412 19.376H16.656ZM11.0376 13.4657L10.3912 15.9114L11.0376 13.4657ZM13.248 15.9114L13.4625 13.4657L13.248 15.9114ZM13.251 15.9114H10.3882L9.70898 18.4793H13.0246L13.251 15.9114ZM13.6264 11.6157H11.5232L11.0346 13.4657H13.4625L13.6264 11.6157ZM19.212 15.9114L18.5656 13.4657L19.212 15.9114ZM16.5756 18.4793H19.8882L19.212 15.9114H16.3492L16.5756 18.4793ZM12.8637 10.716C12.9263 10.4807 13.0336 10.2573 13.1885 10.0458C13.5102 9.59892 13.9451 9.3308 14.4873 9.24441C15.0295 9.15802 15.524 9.27718 15.9708 9.60189C16.3641 9.88788 16.6173 10.2603 16.7305 10.716H12.8637Z"
        stroke={fill}
        strokeWidth="0.7"
        strokeMiterlimit="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2983 1.62503H25.3609C26.4383 1.62503 27.4716 2.05305 28.2335 2.81493C28.9954 3.57682 29.4234 4.61015 29.4234 5.68761V21.9379C29.4234 23.0154 28.9954 24.0487 28.2335 24.8106C27.4716 25.5725 26.4383 26.0005 25.3609 26.0005H4.23543C3.15797 26.0005 2.12464 25.5725 1.36275 24.8106C0.600872 24.0487 0.172852 23.0154 0.172852 21.9379V5.68761C0.172852 4.61015 0.600872 3.57682 1.36275 2.81493C2.12464 2.05305 3.15797 1.62503 4.23543 1.62503H8.29801V0.812516C8.29801 0.597023 8.38362 0.390357 8.53599 0.237981C8.68837 0.0856043 8.89504 0 9.11053 0C9.32602 0 9.53269 0.0856043 9.68507 0.237981C9.83744 0.390357 9.92305 0.597023 9.92305 0.812516V1.62503H19.6732V0.812516C19.6732 0.597023 19.7588 0.390357 19.9112 0.237981C20.0636 0.0856043 20.2703 0 20.4858 0C20.7012 0 20.9079 0.0856043 21.0603 0.237981C21.2127 0.390357 21.2983 0.597023 21.2983 0.812516V1.62503ZM27.0845 23.6615C27.5416 23.2044 27.7984 22.5844 27.7984 21.9379V9.75019H27.7966V8.12516H27.7984V5.68761C27.7984 5.04113 27.5416 4.42113 27.0845 3.96401C26.6273 3.50688 26.0073 3.25006 25.3609 3.25006H21.2983V4.06258C21.2983 4.27807 21.2127 4.48474 21.0603 4.63712C20.9079 4.78949 20.7012 4.8751 20.4858 4.8751C20.2703 4.8751 20.0636 4.78949 19.9112 4.63712C19.7588 4.48474 19.6732 4.27807 19.6732 4.06258V3.25006H9.92305V4.06258C9.92305 4.27807 9.83744 4.48474 9.68507 4.63712C9.53269 4.78949 9.32602 4.8751 9.11053 4.8751C8.89504 4.8751 8.68837 4.78949 8.53599 4.63712C8.38362 4.48474 8.29801 4.27807 8.29801 4.06258V3.25006H4.23543C3.58895 3.25006 2.96895 3.50688 2.51183 3.96401C2.0547 4.42113 1.79788 5.04113 1.79788 5.68761V8.12516H1.80054V9.75019H1.79788V21.9379C1.79788 22.5844 2.0547 23.2044 2.51183 23.6615C2.96895 24.1187 3.58895 24.3755 4.23543 24.3755H25.3609C26.0073 24.3755 26.6273 24.1187 27.0845 23.6615Z"
        fill={fill}
      />
    </svg>
  );
};

InstalledThisMonthIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default InstalledThisMonthIcon;
