import React, { CSSProperties } from "react";

import MenuItem from "@mui/material/MenuItem";
import { SelectProps } from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import { useController } from "react-hook-form";
import { cvar } from "styles";
import { useSmartFormContext } from "components/SmartForm";
import { Tooltip } from "components/mui-styled-components/Tooltip";
import HelperText from "../HelperText";

import * as S from "./styles";

type SmartFormDropdownT = {
  label: string | React.ReactNode;
  value: string | number | boolean | null;
  onClick?: undefined | (() => void);
  disabled?: boolean;
  hidden?: boolean;
  tooltip?: string;
}[];

export type SmartFormDropdownProps = {
  mainLabel?: string;
  dropdownOptions: SmartFormDropdownT;
  name: string;
  helperText?: string | undefined;
  error?: boolean;
  disabled?: boolean;
  width?: string;
  mainLabelStyle?: CSSProperties;
  helperTextStyle?: CSSProperties;
  required?: boolean;
  fullWidth?: boolean;
  prefixIcon?: React.ReactNode;
  prefixIconOnClick?: () => void;
  placeholder?: string;
  hasRedHighlight?: boolean;
} & SelectProps;

const SmartFormDropdown = ({
  mainLabel,
  dropdownOptions,
  name,
  helperText,
  error,
  disabled = false,
  mainLabelStyle = {},
  helperTextStyle = {},
  width = "100%",
  required,
  fullWidth = true,
  prefixIcon,
  prefixIconOnClick,
  placeholder,
  hasRedHighlight = false,
  ...rest
}: SmartFormDropdownProps) => {
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });

  return (
    <>
      <S.LabelGroupWrapper>
        <S.StyledFormLabel style={mainLabelStyle}>{mainLabel}</S.StyledFormLabel>
        {required && <S.StyledRequiredLabel>*</S.StyledRequiredLabel>}
      </S.LabelGroupWrapper>
      <S.StyledDropdown
        sx={{
          background: disabled ? cvar("botticelli-blue-50") : cvar("white"),
          height: "32px",
          width,
          ...(prefixIcon ? { paddingLeft: "8px" } : {}),
        }}
        fullWidth={fullWidth}
        disabled={disabled}
        displayEmpty
        {...field}
        MenuProps={{
          MenuListProps: {
            dense: true,
            sx: { color: cvar("dark-blue"), fontSize: "16px" },
          },
        }}
        {...(prefixIcon
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  <S.PrefixContainer onClick={prefixIconOnClick}>{prefixIcon}</S.PrefixContainer>
                </InputAdornment>
              ),
            }
          : {})}
        IconComponent={() => (
          <S.IconContainer>
            <S.StyledDownCaretFilled />
          </S.IconContainer>
        )}
        defaultValue={rest.defaultValue || ""}
        redHighlight={hasRedHighlight}
        {...rest}
      >
        {placeholder && (
          <MenuItem value="" disabled selected hidden>
            <S.Placeholder>{placeholder}</S.Placeholder>
          </MenuItem>
        )}
        {dropdownOptions.map((opt) => {
          const menuItem = (
            <MenuItem
              value={opt.value}
              key={opt.value}
              onClick={opt?.onClick}
              disabled={opt?.disabled}
              hidden={opt?.hidden}
              style={{ cursor: opt?.disabled ? "not-allowed" : "pointer" }}
            >
              {opt.label}
            </MenuItem>
          );

          return opt.tooltip ? (
            <Tooltip placement="left" title={opt.tooltip}>
              <div>{menuItem}</div>
            </Tooltip>
          ) : (
            menuItem
          );
        })}
      </S.StyledDropdown>
      <HelperText sx={{ marginTop: "-10px", ...helperTextStyle }} error={error}>
        {helperText}
      </HelperText>
    </>
  );
};

export type { SmartFormDropdownT };

export default SmartFormDropdown;
