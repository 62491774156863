import React from "react";
import PropTypes from "prop-types";

const PreferredPartnerModule = ({ fill = "var(--blue-ribbon)", height = "40", width = "40" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill={fill} />
      <path
        d="M25.8384 12.9856C25.5334 11.0607 24.2981 9.44788 22.6071 8.61519L22.8584 11.594C22.8805 11.8513 22.6892 12.076 22.4333 12.0974C22.42 12.0993 22.4065 12.0993 22.3937 12.0993C22.1539 12.0993 21.9506 11.9158 21.9297 11.6724L21.5897 7.64599C20.5202 7.40577 19.4803 7.40577 18.4102 7.64599L18.0714 11.6726C18.0494 11.9289 17.8232 12.1251 17.5676 12.0976C17.3113 12.0761 17.1207 11.8511 17.1427 11.5942L17.3934 8.61538C15.7028 9.44812 14.4672 11.0609 14.1626 12.9858L12.9219 12.9856V14.856C15.2618 15.0496 17.6416 15.15 20.0004 15.15C22.3588 15.15 24.7393 15.0496 27.0789 14.856V12.9856L25.8384 12.9856Z"
        fill="white"
      />
      <path
        d="M20.0002 16.4367C18.2092 16.4367 16.3934 16.3788 14.5806 16.2656C14.5832 16.4807 14.5902 16.6898 14.6039 16.8879C14.7346 18.7156 15.9361 21.4144 16.87 22.9093C17.8233 24.4346 19.9962 24.4165 19.9999 24.4165C20.0036 24.4165 22.1761 24.4344 23.1291 22.9093C24.0635 21.4145 25.2655 18.7157 25.3963 16.8879C25.4102 16.6899 25.417 16.4807 25.4196 16.2656C23.6073 16.379 21.7915 16.4367 19.9999 16.4367H20.0002Z"
        fill="white"
      />
      <path
        d="M11.6878 24.4777C11.5728 24.5155 11.4598 24.5504 11.3435 24.5902C9.99774 25.048 8.99433 26.2671 8.49781 27.6309C8.16217 28.5524 7.97871 29.4281 7.87664 30.1171C7.85778 30.2488 7.84132 30.3729 7.82764 30.4899C7.8539 31.0691 8.32842 31.5324 8.91484 31.5324H11.6876L11.6875 24.4775L11.6878 24.4777Z"
        fill="white"
      />
      <path
        d="M25.8449 23.799C25.2537 23.6657 24.7219 23.5649 24.279 23.4922C24.2599 23.5223 24.2394 23.5613 24.2209 23.59C22.938 25.6433 20.3004 25.7026 20.0038 25.7026H20.0001H19.9964C19.6998 25.7026 17.0622 25.6433 15.7793 23.59C15.761 23.5614 15.7403 23.5223 15.7214 23.4922C15.2783 23.5649 14.7467 23.6657 14.1555 23.799L14.1553 31.5318H25.8449L25.8449 23.799Z"
        fill="white"
      />
      <path
        d="M32.1232 30.1169C32.0211 29.4279 31.8378 28.5522 31.5024 27.6307C31.0059 26.2667 30.0019 25.0477 28.6563 24.59C28.5402 24.5504 28.4272 24.5153 28.312 24.4775V31.5319H31.085C31.6712 31.5319 32.1457 31.0689 32.1722 30.4895C32.1585 30.3726 32.1421 30.2485 32.1232 30.1168L32.1232 30.1169Z"
        fill="white"
      />
    </svg>
  );
};

PreferredPartnerModule.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default PreferredPartnerModule;
