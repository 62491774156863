import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const ArchiveIcon = ({
  fill = "var(--royal-peacock-blue)",
  height = "21",
  width = "19",
  active = false,
  hovered = false,
  containerStyles,
}) => {
  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      style={{ paddingBottom: "1px" }}
      {...containerStyles}
    >
      <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.45714 4.28434C3.12992 3.95711 2.5915 3.97346 2.31641 4.34559C1.35262 5.64937 0.692993 7.18461 0.450613 8.86651C0.384958 9.3221 0.753378 9.71043 1.21368 9.71043H4.72593C5.13357 9.71043 5.46853 9.40092 5.57689 9.00795C5.6875 8.60684 5.85108 8.22968 6.0574 7.8799C6.2645 7.52879 6.24606 7.07325 5.95781 6.78501L3.45714 4.28434Z"
          fill={fill}
        />
        <path
          d="M11.8068 0.429129C11.3512 0.363473 10.9629 0.731894 10.9629 1.19219V4.70445C10.9629 5.11208 11.2724 5.44705 11.6654 5.55541C12.0665 5.66601 12.4436 5.82959 12.7934 6.03592C13.1445 6.24302 13.6001 6.22457 13.8883 5.93633L16.389 3.43566C16.7162 3.10843 16.6999 2.57002 16.3277 2.29493C15.024 1.33113 13.4887 0.671509 11.8068 0.429129Z"
          fill={fill}
        />
        <path
          d="M18.3817 4.34883C18.1058 3.97544 17.5653 3.96025 17.2385 4.29009L14.7671 6.78503C14.4813 7.07361 14.4636 7.52795 14.6643 7.88109C14.8697 8.24234 15.0293 8.63342 15.1432 9.03992C15.2497 9.42014 15.5793 9.71043 15.9741 9.71043H19.5035C19.9685 9.71043 20.3383 9.31462 20.2663 8.85529C20.0034 7.17918 19.3425 5.64901 18.3817 4.34883Z"
          fill={fill}
        />
        <path
          d="M16.0038 10.9375C15.5962 10.9375 15.2612 11.247 15.1529 11.64C15.0419 12.0422 14.8778 12.4204 14.6706 12.771C14.464 13.1207 14.4813 13.5743 14.7671 13.8629L17.2385 16.3578C17.5653 16.6877 18.1058 16.6725 18.3817 16.2991C19.3442 14.996 20.0029 13.4619 20.2451 11.7814C20.3107 11.3258 19.9423 10.9375 19.482 10.9375H16.0038Z"
          fill={fill}
        />
        <path
          d="M7.90498 14.6471C7.55291 14.4445 7.099 14.4617 6.81042 14.7476L4.31548 17.219C3.98564 17.5457 4.00081 18.0863 4.37426 18.3622C5.67733 19.3246 7.21141 19.9834 8.8919 20.2255C9.34749 20.2912 9.73582 19.9228 9.73582 19.4625V15.9585C9.73582 15.5619 9.44301 15.232 9.06077 15.1264C8.64929 15.0127 8.26281 14.853 7.90498 14.6471Z"
          fill={fill}
        />
        <path
          d="M5.54962 11.6126C5.44433 11.2303 5.11423 10.9375 4.71768 10.9375H1.21367C0.753377 10.9375 0.384958 11.3258 0.450614 11.7814C0.692993 13.4633 1.35262 14.9986 2.31641 16.3023C2.5915 16.6745 3.12992 16.6908 3.45714 16.3636L5.93567 13.8851C6.21881 13.6019 6.24247 13.1561 6.04422 12.8082C5.83091 12.4339 5.66604 12.0354 5.54962 11.6126Z"
          fill={fill}
        />
        <path
          d="M13.8883 14.7524C13.6001 14.4642 13.1438 14.4453 12.7893 14.6465C12.4288 14.851 12.0388 15.0101 11.6334 15.1236C11.2532 15.2302 10.9629 15.5597 10.9629 15.9546V19.4965C10.9629 19.9568 11.3512 20.3253 11.8068 20.2596C13.4887 20.0172 15.024 19.3576 16.3277 18.3938C16.6999 18.1187 16.7162 17.5803 16.389 17.2531L13.8883 14.7524Z"
          fill={fill}
        />
        <path
          d="M4.37096 2.29489C3.99887 2.57004 3.9825 3.10843 4.30973 3.43566L6.77633 5.90225C7.06457 6.1905 7.52083 6.20937 7.87537 6.00819C8.2358 5.80366 8.62585 5.64461 9.03124 5.53103C9.41146 5.4245 9.70175 5.09493 9.70175 4.70007V1.19293C9.70175 0.73236 9.31296 0.363834 8.85729 0.430832C7.19944 0.674584 5.67125 1.33336 4.37096 2.29489Z"
          fill={fill}
        />
        <path
          d="M12.8691 11.7573C12.835 12.7795 11.9491 13.4268 11.0292 13.5631C11.0292 13.5631 11.0292 13.8016 11.0292 14.006C11.0292 14.2105 10.8588 14.3808 10.6544 14.3808H10.3137C10.1092 14.3808 9.93888 14.2105 9.93888 14.006V13.5972C9.49595 13.529 9.12115 13.3928 8.78043 13.1883C8.40564 12.9498 8.13307 12.6091 7.92863 12.2343C7.82642 12.0299 7.4857 11.3825 7.92863 11.3825C8.23528 11.3825 8.50786 11.3825 8.81451 11.3825C8.88265 11.3825 8.98487 11.3825 9.05301 11.4166C9.1893 11.4847 9.1893 11.5869 9.22337 11.7232C9.35966 12.4728 10.3818 12.6091 10.961 12.3365C11.6084 12.0299 11.3358 11.4847 10.8588 11.1781C9.83667 10.5648 8.43971 10.9396 7.99678 9.57668C7.7242 8.75896 8.13307 7.90716 8.84858 7.46422C9.12115 7.29386 9.4278 7.19164 9.76852 7.1235V6.68056C9.76852 6.47613 9.93888 6.33984 10.1092 6.33984H10.484C10.6885 6.33984 10.8248 6.5102 10.8248 6.68056V7.08943C11.2677 7.15757 11.6425 7.29386 11.9832 7.49829C12.4261 7.77087 12.7669 8.2138 12.9372 8.72488C12.9713 8.8271 13.0054 8.96339 13.0394 9.09968C13.0394 9.40632 12.7328 9.37225 12.4943 9.37225C12.2899 9.37225 12.0854 9.37225 11.881 9.33818C11.7106 9.33818 11.6425 9.23596 11.6084 9.0656V9.03153C11.5743 8.8271 11.4381 8.62267 11.2336 8.48638C10.8248 8.24788 10.3137 8.2138 9.90481 8.38416C9.25744 8.69081 9.53002 9.23597 10.007 9.54261C10.5863 9.88333 11.3018 9.81519 11.881 10.0878C12.1536 10.19 12.3921 10.3944 12.5965 10.6329C12.7328 10.9396 12.8691 11.3484 12.8691 11.7573Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

ArchiveIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

export default ArchiveIcon;
