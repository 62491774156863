import React from "react";
import PropTypes from "prop-types";
import styles from "./ConferenceIcon.module.less";

const ConferenceIcon = ({ height = "23", width = "23", shade }) => {
  return (
    <div
      style={{ height, width }}
      className={shade === "light" ? styles.conferenceIcon_light : styles.conferenceIcon_dark}
    />
  );
};

ConferenceIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  shade: PropTypes.oneOf(["dark", "light"]),
};

export default ConferenceIcon;
