import React from "react";
import PropTypes from "prop-types";

const BellIcon = ({ width = "18", height = "20", fill = "white" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3643 2.38784C4.54114 3.11407 2.45511 5.67594 2.45511 8.72683V13.0901C2.45511 13.4921 2.12841 13.8181 1.72862 13.8181C0.924796 13.8181 0.273438 14.471 0.273438 15.2725C0.273438 16.0758 0.925835 16.7272 1.73141 16.7272H16.2696C17.075 16.7272 17.7276 16.0742 17.7276 15.2725C17.7276 14.4692 17.0732 13.8181 16.2724 13.8181C15.8711 13.8181 15.5459 13.4947 15.5459 13.0901V8.72683C15.5459 5.67691 13.4604 3.11427 10.6367 2.38784V1.63655C10.6367 0.730514 9.90422 0 9.00046 0C8.09845 0 7.36417 0.732787 7.36417 1.63655L7.3643 2.38784ZM6.45521 17.4546H11.546C11.546 18.8603 10.4063 20 9.00059 20C7.59488 20 6.45521 18.8603 6.45521 17.4546Z"
        fill={fill}
      />
    </svg>
  );
};

BellIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default BellIcon;
