import React from "react";
import { MortarCapIcon } from "components/Icons";
import PropTypes from "prop-types";

const PowurUniversityGradientIcon = ({ height = "100", width = "91", containerStyles }) => {
  return (
    <div className={containerStyles}>
      <MortarCapIcon height={height} width={width} />
    </div>
  );
};

PowurUniversityGradientIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  containerStyles: PropTypes.string,
};

export default PowurUniversityGradientIcon;
