import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const Settings = ({
  fill = "var(--royal-peacock-blue)",
  height = "25",
  width = "25",
  active = false,
  hovered = false,
  containerStyles,
}) => {
  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      style={{
        paddingRight: "1px",
        paddingTop: "1px",
      }}
      {...containerStyles}
    >
      <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4556 9.62687C14.4093 9.70015 14.4077 9.79485 14.4601 9.86385C14.8856 10.4237 15.4081 10.8886 15.9971 11.2455C16.0718 11.2908 16.1665 11.2788 16.2345 11.2241C16.698 10.8522 17.3169 10.6811 17.9441 10.8149C18.5716 10.9457 19.0698 11.3515 19.3448 11.8798C19.3849 11.9569 19.4663 12.0059 19.5525 11.9948C19.8902 11.9512 20.2275 11.8735 20.5623 11.7637C20.8999 11.654 21.2178 11.5148 21.5138 11.3504C21.5896 11.3083 21.6261 11.2209 21.613 11.1352C21.5233 10.5452 21.6875 9.92039 22.1159 9.44139C22.541 8.96282 23.1419 8.73147 23.7332 8.75497C23.8201 8.75842 23.9031 8.71229 23.9365 8.63211C24.2023 7.99539 24.3505 7.30879 24.3635 6.60274C24.3651 6.51563 24.3074 6.43988 24.2262 6.40826C23.6731 6.19286 23.2157 5.73733 23.0166 5.12799C22.8172 4.51509 22.9179 3.87742 23.237 3.3728C23.2833 3.29955 23.2849 3.20485 23.2325 3.13586C22.8067 2.57611 22.2819 2.11129 21.6928 1.75445C21.6179 1.70909 21.5229 1.7213 21.4547 1.77628C20.9913 2.15027 20.3724 2.31883 19.7453 2.18806C19.1183 2.05432 18.6203 1.64877 18.3452 1.12105C18.3047 1.04344 18.2225 0.99435 18.1357 1.00596C17.7985 1.0511 17.4615 1.12634 17.1271 1.23596C16.7917 1.34586 16.4736 1.4854 16.175 1.6524C16.0995 1.6946 16.0633 1.7818 16.0763 1.86726C16.1662 2.45694 16.0022 3.07921 15.5767 3.55831C15.1486 4.03688 14.5503 4.27097 13.9568 4.24536C13.8697 4.2416 13.7862 4.28773 13.7526 4.36824C13.4874 5.00495 13.3418 5.69349 13.329 6.39696C13.3275 6.48408 13.3853 6.55982 13.4664 6.59146C14.0191 6.80688 14.4737 7.26263 14.6728 7.87493C14.8722 8.48484 14.7743 9.12228 14.4556 9.62687ZM16.3261 7.33133C16.7829 8.72731 18.2786 9.48964 19.6714 9.02967C21.0641 8.57292 21.82 7.06757 21.3665 5.67159C20.9097 4.27238 19.4108 3.51328 18.018 3.97003C16.6285 4.42678 15.8694 5.93213 16.3261 7.33133Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.70496 15.993C1.65752 16.0655 1.65609 16.1602 1.70805 16.2295C2.26425 16.9717 2.95355 17.5851 3.72883 18.0484C3.80374 18.0932 3.89851 18.0814 3.9657 18.0257C4.56293 17.5307 5.36788 17.3038 6.18131 17.4779C6.99468 17.6489 7.64146 18.1814 7.98886 18.8734C8.02799 18.9514 8.10993 19.0003 8.19647 18.9895C8.6398 18.9343 9.08496 18.8369 9.52494 18.6902C9.96818 18.5463 10.3855 18.3609 10.7768 18.1409C10.8526 18.0983 10.8895 18.0107 10.875 17.925C10.7457 17.1609 10.9508 16.3445 11.5062 15.7231C12.0614 15.1019 12.8452 14.8053 13.616 14.8493C13.703 14.8542 13.7863 14.8077 13.8202 14.7273C14.1724 13.8918 14.3669 12.9859 14.3775 12.0592C14.3785 11.9725 14.3213 11.8968 14.24 11.8664C13.5162 11.5952 12.9162 11.0089 12.658 10.2163C12.3968 9.42068 12.534 8.59113 12.9578 7.94305C13.0054 7.87043 13.0067 7.77555 12.9545 7.70619C12.3984 6.96654 11.7092 6.35085 10.9341 5.88737C10.8592 5.84258 10.7645 5.85438 10.6973 5.91008C10.1 6.40514 9.29507 6.63221 8.48165 6.46112C7.66827 6.29004 7.02148 5.75473 6.67409 5.06237C6.63497 4.98442 6.55303 4.93552 6.4665 4.94638C6.02316 5.00204 5.578 5.10176 5.13802 5.24555C4.69504 5.39225 4.27799 5.57785 3.88921 5.79534C3.81349 5.8377 3.77638 5.92499 3.79054 6.01059C3.91736 6.7772 3.7123 7.59106 3.15673 8.2127C2.60147 8.83699 1.82033 9.13093 1.04971 9.08655C0.962877 9.08154 0.879781 9.12785 0.845791 9.20791C0.491078 10.0435 0.298937 10.9494 0.28587 11.8761C0.284644 11.9631 0.34192 12.0391 0.423372 12.0696C1.14702 12.3408 1.74683 12.9273 2.00497 13.7227C2.26621 14.5154 2.12897 15.3449 1.70496 15.993ZM4.11388 13.0271C4.69773 14.8106 6.60883 15.7837 8.38593 15.1999C10.163 14.6128 11.1329 12.6921 10.5491 10.9087C9.96522 9.12517 8.05412 8.15208 6.27702 8.73594C4.49992 9.32298 3.53002 11.2437 4.11388 13.0271Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3417 21.9252C14.2953 21.9985 14.2937 22.0932 14.3462 22.1622C14.7753 22.7269 15.3045 23.1979 15.8986 23.5553C15.9732 23.6002 16.0676 23.5881 16.1354 23.5336C16.6022 23.1588 17.2256 22.9887 17.8546 23.1205C18.4868 23.2523 18.9914 23.6613 19.2686 24.1936C19.3087 24.2707 19.3901 24.3198 19.4763 24.3088C19.8166 24.2656 20.1566 24.1897 20.4941 24.0791C20.8315 23.9657 21.1516 23.8249 21.4521 23.6591C21.5282 23.617 21.5651 23.5294 21.552 23.4434C21.4615 22.8493 21.6266 22.22 22.0551 21.7376C22.4863 21.2554 23.0892 21.0225 23.6872 21.0465C23.7742 21.05 23.8574 21.0035 23.8907 20.923C24.156 20.2816 24.3051 19.5898 24.3181 18.8785C24.3197 18.7914 24.2619 18.7156 24.1807 18.684C23.6238 18.4673 23.1655 18.0085 22.9619 17.3946C22.7612 16.7775 22.8625 16.1355 23.1838 15.63C23.2304 15.5567 23.2322 15.4617 23.1796 15.3925C22.7509 14.8283 22.2224 14.3576 21.6315 13.9979C21.5566 13.9523 21.4613 13.9644 21.3931 14.0195C20.9264 14.3964 20.3031 14.5663 19.6715 14.4346C19.04 14.2999 18.5357 13.8942 18.2609 13.3606C18.2206 13.2824 18.138 13.2327 18.0508 13.2443C17.7106 13.2898 17.3688 13.3655 17.032 13.4759C16.6948 13.5865 16.3749 13.7292 16.0745 13.8955C15.9981 13.9378 15.9613 14.026 15.9748 14.1123C16.0677 14.7062 15.9023 15.3352 15.471 15.8174C15.0399 16.2994 14.4373 16.5323 13.8396 16.506C13.7522 16.5021 13.6685 16.5486 13.6351 16.6295C13.3699 17.2711 13.221 17.9651 13.208 18.674C13.2064 18.7608 13.2637 18.8364 13.3445 18.868C13.9022 19.0868 14.3634 19.5432 14.5642 20.1604C14.765 20.7749 14.6635 21.417 14.3417 21.9252ZM16.2256 19.6131C16.6855 21.0186 18.1946 21.7862 19.597 21.3263C20.996 20.8632 21.7603 19.3475 21.3005 17.942C20.8406 16.5364 19.3314 15.7689 17.9323 16.2287C16.53 16.6919 15.7657 18.2043 16.2256 19.6131Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

Settings.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  active: PropTypes.bool,
  hovered: PropTypes.bool,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

export default Settings;
