import React from "react";
import PropTypes from "prop-types";

const undoArrow = ({ fill = "white", height = "10", width = "14" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 35 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4919 10.832H9.17761L15.3662 4.63691C16.0487 3.95075 16.3151 2.95055 16.0656 2.01321C15.8158 1.07588 15.0873 0.343848 14.155 0.092639C13.2227 -0.158538 12.2278 0.10932 11.5454 0.795804L0.782345 11.6169V11.6166C0.281414 12.1235 0 12.8093 0 13.5241C0 14.2385 0.281414 14.9243 0.782345 15.4312L11.5454 26.2523C12.2231 26.9385 13.2135 27.2089 14.1436 26.9619C15.0734 26.7149 15.8018 25.988 16.0539 25.0548C16.3059 24.1216 16.044 23.1239 15.3663 22.4378L9.17765 16.2431H17.4919C20.3747 16.2392 23.1639 17.2696 25.3585 19.1488C27.553 21.0276 29.0089 23.6324 29.4639 26.4945C29.9192 29.3562 29.3437 32.2872 27.8415 34.7608C26.339 37.2344 24.0082 39.0876 21.2681 39.9877C18.528 40.8878 15.5585 40.7752 12.8934 39.6707C10.2282 38.5663 8.04261 36.5417 6.72935 33.9616C6.41763 33.3011 5.85292 32.7953 5.16435 32.5602C4.47549 32.3247 3.72154 32.3799 3.07382 32.7129C2.42611 33.0455 1.94017 33.6281 1.72641 34.327C1.51295 35.0263 1.58985 35.7825 1.94019 36.4234C3.83608 40.1506 6.99254 43.0749 10.8417 44.6716C14.6909 46.2679 18.9802 46.4308 22.9387 45.1316C26.8967 43.8325 30.2641 41.1561 32.4349 37.5842C34.6058 34.0119 35.4376 29.7781 34.7813 25.6445C34.125 21.5109 32.0229 17.7481 28.8537 15.0328C25.6844 12.3177 21.6559 10.8286 17.4919 10.833L17.4919 10.832Z"
        fill={fill}
      />
    </svg>
  );
};

undoArrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default undoArrow;
