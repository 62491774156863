import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./ChevronVertical.module.less";

const ChevronVertical = ({
  fill = "var(--light-grey)",
  hoverFill = "var(--dark-blue)",
  outerWidth = "20px",
  outerHeight = "19px",
  rotate = false,
}) => {
  const [currentFill, setCurrentFill] = useState(fill);

  return (
    <div
      className={styles.chevronVertical}
      onMouseOver={() => setCurrentFill(hoverFill)}
      onFocus={() => setCurrentFill(hoverFill)}
      onMouseOut={() => setCurrentFill(fill)}
      onBlur={() => setCurrentFill(fill)}
    >
      <svg
        width={outerWidth}
        height={outerHeight}
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={rotate ? styles.chevronVertical : styles.chevronVerticalRotated}
      >
        <circle cx="8.90234" cy="8" r="8" fill="var(--athens-gray)" />
        <path
          d="M5.46414 7.59456C5.18761 7.79413 5.18761 8.20587 5.46414 8.40544L10.1097 11.7581C10.4404 11.9968 10.9023 11.7605 10.9023 11.3527L10.9023 4.64733C10.9023 4.23952 10.4404 4.00324 10.1097 4.24188L5.46414 7.59456Z"
          fill={currentFill}
          className={styles.triangle}
        />
      </svg>
    </div>
  );
};

ChevronVertical.propTypes = {
  outerWidth: PropTypes.string,
  outerHeight: PropTypes.string,
  fill: PropTypes.string,
  hoverFill: PropTypes.string,
  rotate: PropTypes.bool,
};

export default ChevronVertical;
