import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const Compensation = ({
  height = "20",
  width = "20",
  fill = "var(--royal-peacock-blue)",
  active = false,
  hovered = false,
}) => {
  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
    >
      <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.38086 11.8805L10.1187 3.14455C10.262 3.00431 10.4444 2.91079 10.6419 2.87633L14.9333 2.12079C15.0891 2.09296 15.2492 2.10315 15.4002 2.15051C15.5511 2.19786 15.6884 2.28098 15.8003 2.39279L17.4833 4.07575C17.2784 4.31084 17.0272 4.50113 16.7454 4.63471C16.4637 4.76828 16.1573 4.84228 15.8457 4.85207C15.5865 4.85594 15.3293 4.80643 15.0901 4.70663C14.9771 4.65181 14.8531 4.62339 14.7275 4.62352C14.5377 4.62286 14.3534 4.68673 14.2047 4.80463C14.056 4.92254 13.9518 5.08748 13.9092 5.2724C13.8666 5.45732 13.8881 5.65123 13.9702 5.82231C14.0523 5.99339 14.1901 6.13148 14.361 6.21393C14.9558 6.46463 15.6049 6.55876 16.2464 6.48733C16.8878 6.41591 17.5003 6.18132 18.0254 5.80594L17.3397 9.58363C17.304 9.78039 17.2091 9.96158 17.0677 10.1031L8.33182 18.8314C8.24072 18.923 8.13241 18.9957 8.01312 19.0453C7.89383 19.0949 7.76592 19.1205 7.63672 19.1205C7.50753 19.1205 7.37961 19.0949 7.26033 19.0453C7.14104 18.9957 7.03273 18.923 6.94163 18.8314L1.38086 13.265C1.19895 13.0805 1.09697 12.8318 1.09697 12.5727C1.09697 12.3136 1.19895 12.065 1.38086 11.8805Z"
          fill={fill}
        />
        <path
          d="M18.1099 5.30376C17.6252 5.73176 17.0301 6.01513 16.3923 6.12165C15.7545 6.22817 15.0996 6.15356 14.5022 5.9063C14.4409 5.87883 14.3857 5.83935 14.3399 5.7902C14.2942 5.74104 14.2587 5.68321 14.2357 5.62012C14.2127 5.55703 14.2025 5.48996 14.2058 5.42288C14.2092 5.35579 14.2259 5.29006 14.2551 5.22956C14.2843 5.16907 14.3253 5.11503 14.3757 5.07066C14.4261 5.02628 14.4849 4.99246 14.5486 4.97121C14.6123 4.94995 14.6797 4.94168 14.7466 4.94689C14.8136 4.9521 14.8788 4.97069 14.9385 5.00154C15.2194 5.12231 15.5224 5.18342 15.8281 5.18098C16.3513 5.18098 17.0427 5.00343 17.717 4.30078L17.8435 4.42922C17.9552 4.54266 18.0378 4.68146 18.0842 4.83376C18.1306 4.98606 18.1394 5.14732 18.1099 5.30376Z"
          fill={fill}
        />
        <path
          d="M14.2761 5.24163C14.3043 5.18189 14.3442 5.12838 14.3934 5.08419C14.4425 5.04 14.4999 5.00602 14.5623 4.98423C14.6247 4.96243 14.6908 4.95326 14.7568 4.95724C14.8228 4.96122 14.8873 4.97827 14.9466 5.00741C15.2275 5.12818 15.5305 5.18928 15.8363 5.18685C16.5332 5.18685 17.5211 4.8733 18.3654 3.43022C18.8452 2.60857 18.6185 1.91914 18.2785 1.57348C18.1708 1.46399 18.0418 1.37756 17.8996 1.31944C17.7574 1.26131 17.6048 1.23272 17.4512 1.23538C17.1633 1.24173 16.8821 1.32312 16.6352 1.47149C16.5785 1.51352 16.5135 1.5431 16.4445 1.55831C16.3755 1.57352 16.3041 1.57402 16.2349 1.55976C16.1657 1.5455 16.1003 1.51682 16.043 1.47558C15.9856 1.43433 15.9376 1.38146 15.9021 1.32041C15.8665 1.25936 15.8443 1.1915 15.8367 1.12127C15.8292 1.05103 15.8366 0.979994 15.8583 0.912794C15.8801 0.845593 15.9158 0.78374 15.9631 0.73127C16.0104 0.6788 16.0682 0.636892 16.1328 0.608282C16.5325 0.367966 16.9887 0.237636 17.455 0.230513C17.7403 0.228682 18.0232 0.28388 18.2869 0.392865C18.5506 0.501851 18.7898 0.662428 18.9906 0.865166C19.7575 1.6377 19.8557 2.8749 19.2324 3.93643C18.1633 5.76673 16.8279 6.17472 15.8438 6.17472C15.3843 6.17819 14.9288 6.08889 14.5046 5.91217C14.3857 5.85325 14.2949 5.74967 14.2521 5.62403C14.2092 5.4984 14.2179 5.36092 14.2761 5.24163V5.24163Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

Compensation.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

export default Compensation;
