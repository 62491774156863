import React, { useState } from "react";
import PropTypes from "prop-types";
import { Menu, Tooltip } from "antd";
import styles from "./ActionsDropdown.module.less";

const MenuItem = ({ text, danger, reason, handleClick, statusAction, canRequest }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const requestDisabled = () => {
    switch (reason) {
      case null:
        return "";
      case undefined:
        return "";
      case "qualify_incomplete":
        return "Proposal Form Incomplete";
      case "already_requested":
        return "Proposal Already Requested";
      case "lightreach_already_requested":
        return "LightReach Proposal Already Requested";
      case "proposal_request_in_progress":
        return "Proposal Request in Progress";
      case "no_mentor":
        return "You must assign a Sales Partner before requesting a proposal";
      case "only_mentor":
        return "You must have your Sales Partner request this proposal";
      case "only_seller":
        return "Only the Assigned Seller or their Sales Partner can submit this proposal";
      case "service_area_unavailable":
        return "Powur doesn't support this area currently";
      case "roofing_in_progress":
        return "Roofing Proposal In Progress";
      case "site_survey_not_scheduled":
      case "permit_submitted":
        return "Your project must be within the Site Survey Scheduled and Design Completed milestones.";
      case "only_mentor_or_seller":
        return "You must be a Sales Pro or a Closer in order to initiate a change order.";
      case "change_order_in_progress":
        return "Change Order In Progress";
      case "outside_vision_regions":
        return "Vision Proposals are only available in select markets.";
      case "outside_solo_regions":
        return "Solo Proposals are only available in select markets.";
      case "outside_lightreach_regions":
        return "LightReach Proposals are only available in select markets.";
      case "uncertified_enterprise_user":
        return "You must complete Enterprise Certification before Submitting a Proposal";
      case "certification_needed":
        return "You must complete Sunnova Certification before Submitting a Sunnova Proposal";
      case "proposal_needed":
        return "GoSolo or Vision Proposal must be complete before submitting a new Sunnova Proposal";
      case "sunnova_in_progress":
        return "Cannot submit a Sunnova Proposal while a previous version is in Progress.";
      case "outside_sunnova_regions":
        return "Sunnova Proposals are only available in select markets.";
      case "Closed by PowurOS":
        return "Projects closed by PowurOS cannot be reopened.";
      case "rsm_only":
        return "RSM Only";
      case "missing_aurora_permission":
        return "Complete the Aurora Certification to unlock this option.";
      default:
        throw new Error(`invalid reason: ${reason}`);
    }
  };

  return (
    <div
      onMouseEnter={() => {
        setTooltipVisible(true);
      }}
      onMouseLeave={() => {
        setTooltipVisible(false);
      }}
    >
      <Tooltip
        open={tooltipVisible && !statusAction && !!reason && !canRequest}
        placement="right"
        title={requestDisabled()}
      >
        <Menu.Item
          disabled={!statusAction ? !canRequest : false}
          style={{
            paddingLeft: 10,
            height: "30px",
            borderRadius: 6,
            background: "white",
          }}
          className={danger ? styles.dangerItem : canRequest && styles.menuItem}
          onClick={() => {
            handleClick();
          }}
        >
          <span className={styles.menuItemText}>{text}</span>
        </Menu.Item>
      </Tooltip>
    </div>
  );
};

MenuItem.propTypes = {
  text: PropTypes.string,
  danger: PropTypes.bool,
  reason: PropTypes.string,
  canRequest: PropTypes.bool,
  handleClick: PropTypes.func,
  statusAction: PropTypes.bool,
};

export default MenuItem;
