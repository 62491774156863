import React from "react";
import PropTypes from "prop-types";

const ArchiveIcon = ({ mainColor = "white", height = "14", width = "14", containerStyles }) => {
  return (
    <div style={{ paddingTop: "3px", ...containerStyles }}>
      <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.28571 0.714286L0.714286 0.714286L0.714286 1.78571L9.28571 1.78571L9.28571 0.714286ZM0.714286 -4.05891e-07C0.319797 -4.23135e-07 -1.39788e-08 0.319797 -3.12224e-08 0.714286L-7.80561e-08 1.78571C-9.3978e-08 2.14997 0.272651 2.45054 0.625 2.49447L0.625 8.48243C0.625 9.27141 1.26459 9.911 2.05357 9.911L7.94643 9.911C8.73541 9.911 9.375 9.27141 9.375 8.48243L9.375 2.49447C9.72735 2.45054 10 2.14997 10 1.78571L10 0.714286C10 0.319797 9.6802 -1.39788e-08 9.28571 -3.12224e-08L0.714286 -4.05891e-07ZM4.74008 2.50029L1.33929 2.50029L1.33929 8.48243C1.33929 8.87692 1.65908 9.19671 2.05357 9.19671L7.94643 9.19671C8.34092 9.19671 8.66071 8.87692 8.66071 8.48243L8.66071 2.50029L5.28561 2.50029L5.28561 5.90399L6.40941 4.7802C6.51592 4.67372 6.68863 4.67372 6.79516 4.7802C6.90162 4.88669 6.90162 5.05944 6.79511 5.16586L5.23131 6.72966C5.22987 6.7311 5.22835 6.73232 5.22686 6.73369C5.17693 6.797 5.09968 6.83777 5.01281 6.83777C4.92318 6.83777 4.84382 6.79431 4.79417 6.72753C4.78538 6.72071 4.77679 6.71341 4.76871 6.70533L3.20488 5.14154C3.09837 5.03503 3.09837 4.86234 3.20488 4.7558C3.31139 4.64933 3.48412 4.64933 3.59063 4.7558L4.74008 5.90523L4.74008 2.50029Z"
          fill={mainColor}
        />
      </svg>
    </div>
  );
};

ArchiveIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  mainColor: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

export default ArchiveIcon;
