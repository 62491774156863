import React from "react";
import PropTypes from "prop-types";

const StatusIconComplete = ({
  accentColor = "var(--diamond-blue)",
  mainColor = "var(--dark-blue)",
  size = "48",
  containerStyles,
}) => {
  return (
    <div
      style={{
        // paddingTop: "3px",
        lineHeight: "10px",
        ...containerStyles,
      }}
    >
      <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24" r="24" fill={accentColor} />
        <circle cx="24" cy="24" r="18" fill={mainColor} />
        <rect
          x="21.0703"
          y="31.7982"
          width="2"
          height="18"
          rx="0.5"
          transform="rotate(-135 21.0703 31.7982)"
          fill={accentColor}
        />
        <rect
          x="22.4845"
          y="30.3846"
          width="2"
          height="9.99947"
          rx="0.5"
          transform="rotate(135 22.4845 30.3846)"
          fill={accentColor}
        />
      </svg>
    </div>
  );
};

StatusIconComplete.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mainColor: PropTypes.string,
  accentColor: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

export default StatusIconComplete;
