import React from "react";
import PropTypes from "prop-types";

const PlusOutlined = ({ fill = "var(--dark-blue)", height = "10", width = "10", innerFill = "white", className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="10" height="10" rx="5" fill={fill} />
      <path
        d="M4.27612 5.63303H2.00854V4.34982H4.27612V2.06467H5.55933V4.34982H7.8269V5.63303H5.55933V7.88889H4.27612V5.63303Z"
        fill={innerFill}
      />
    </svg>
  );
};

PlusOutlined.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  innerFill: PropTypes.string,
  className: PropTypes.string,
};

export default PlusOutlined;
