import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const OnlineMarketingIcon = ({ active, hovered, fill = "white", height = "29", width = "26", containerStyles }) => {
  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      {...containerStyles}
    >
      <svg width={width} height={height} viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M25.3184 6.78718L25.0195 6L22.5287 6.94501L22.8275 7.73218L25.3184 6.78718Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.2"
        />
        <path
          d="M22.2859 1.79604L21.5566 1.375L20.2234 3.68438L20.9527 4.10542L22.2859 1.79604Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.2"
        />
        <path
          d="M26.0255 12.0232L23.7188 10.6914L23.2977 11.4207L25.6045 12.7525L26.0255 12.0232Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.2"
        />
        <path
          d="M10.4165 15.6375L10.5744 15.578L10.5148 15.4201L10.512 15.4212L8.21481 9.33303L4.00391 10.9219L4.06347 11.0797L6.36064 17.1679L10.4165 15.6375Z"
          fill={fill}
        />
        <path
          d="M10.2502 16.3618L7.41992 17.4297L9.95661 21.0501C10.3401 21.5973 11.0458 21.8164 11.6744 21.5792C12.57 21.2413 12.906 20.1504 12.3579 19.3669L10.2502 16.3618Z"
          fill={fill}
        />
        <path d="M3.7753 12.0784L2.73047 12.4727L4.38755 16.8644L5.43238 16.4702L3.7753 12.0784Z" fill={fill} />
        <path
          d="M18.6504 6.30859L20.406 10.9615C21.3613 10.3125 21.7857 9.07359 21.359 7.94264C20.9334 6.81457 19.7963 6.16479 18.6504 6.30859Z"
          fill={fill}
        />
        <path
          d="M11.2406 15.6285C13.7823 15.8467 18.0282 16.4711 19.9084 16.6831L14.4931 2.33103C13.2176 3.73043 10.4465 7.00581 8.68246 8.84858L11.2406 15.6285V15.6285Z"
          fill={fill}
        />
        <path
          d="M15.2167 1.64169C15.1966 1.64928 15.1816 1.66151 15.1615 1.6691C15.1604 1.66623 15.1604 1.66623 15.1593 1.66336C15.1575 1.66731 15.1557 1.67126 15.1529 1.67235C15.1224 1.68713 15.0557 1.74508 14.9622 1.83611L20.5887 16.7479C20.719 16.7545 20.8073 16.754 20.84 16.7449C20.844 16.7467 20.8479 16.7485 20.8508 16.7474C20.8497 16.7445 20.8497 16.7445 20.8486 16.7417C20.8687 16.7341 20.8881 16.7333 20.9082 16.7257C21.3962 16.5416 21.6428 15.996 21.4587 15.5081L16.4344 2.1922C16.2532 1.70315 15.7076 1.45649 15.2167 1.64169Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

OnlineMarketingIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

export default OnlineMarketingIcon;
