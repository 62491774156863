import React from "react";
import PropTypes from "prop-types";

const EditIcon = ({ mainColor = "var(--royal-peacock-blue)", height = "24", width = "24", containerStyles = {} }) => {
  return (
    <div style={{ paddingTop: "1px", paddingLeft: "2px", ...containerStyles }}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.2751 14.2872L6.67596 17.2163C6.64002 17.392 6.7953 17.5472 6.97099 17.5113L9.9001 16.9122L16.3501 10.5372L13.6501 7.83716L7.2751 14.2872Z"
          fill={mainColor}
        />
        <path
          d="M16.607 6.29427C16.2165 5.90374 15.5833 5.90374 15.1928 6.29426L14.3999 7.08716L17.0999 9.78716L17.8928 8.99427C18.2833 8.60374 18.2833 7.97058 17.8928 7.58005L16.607 6.29427Z"
          fill={mainColor}
        />
      </svg>
    </div>
  );
};

EditIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  mainColor: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

export default EditIcon;
