import React from "react";
import PropTypes from "prop-types";

const GeneralUpdateIcon = ({
  iconColor = "white",
  circleColor = "var(--blue-ribbon)",
  height = 40,
  width = 40,
  containerStyles = {},
}) => {
  return (
    <div style={{ paddingTop: "3px", ...containerStyles }}>
      <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill={circleColor} />
        <path
          d="M22.8077 12.4769C22.2165 11.4852 21.1676 10.875 20.0042 10.875C18.8409 10.875 17.7919 11.4662 17.2008 12.4769L11.4222 22.1651C10.7929 23.1949 10.7929 24.4154 11.384 25.4644C11.9752 26.4941 13.0432 27.1235 14.2257 27.1235H25.7828C26.9843 27.1235 28.0331 26.5132 28.6244 25.4644C29.2156 24.4346 29.1966 23.1949 28.5862 22.1651L22.8077 12.4769ZM20.0042 24.5871C19.2795 24.5871 18.6883 23.996 18.6883 23.2712C18.6883 22.5464 19.2795 21.9552 20.0042 21.9552C20.729 21.9552 21.3202 22.5464 21.3202 23.2712C21.3202 23.996 20.729 24.5871 20.0042 24.5871ZM21.3774 15.6618L20.996 20.01C20.9769 20.277 20.8434 20.525 20.6336 20.6966C20.462 20.8492 20.233 20.9254 19.9852 20.9254H19.8898C19.413 20.8872 19.0125 20.5059 18.9744 20.01L18.593 15.6618C18.5549 15.2994 18.6693 14.9371 18.9172 14.651C19.1652 14.365 19.4894 14.1933 19.8517 14.1552C20.2331 14.1171 20.5765 14.2315 20.8625 14.4794C21.1485 14.7082 21.3203 15.0516 21.3583 15.4139C21.3964 15.5092 21.3964 15.5856 21.3774 15.6618L21.3774 15.6618Z"
          fill={iconColor}
        />
      </svg>
    </div>
  );
};

GeneralUpdateIcon.propTypes = {
  iconColor: PropTypes.string,
  circleColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

export default GeneralUpdateIcon;
