import React from "react";
import PropTypes from "prop-types";

const CCIcon = ({
  height = "9px",
  width = "14px",
  insideColor = "var(--canary-yellow)",
  outsideColor = "var(--dark-pink)",
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="4.62184" cy="9.43915" rx="3.30348" ry="3.987" fill={insideColor} />
      <path
        d="M5.48259 13.2725C9.54185 9.6588 3.61373 9.29064 4.7272 5.88474C4.7272 5.88474 3.13004 7.22216 3.66506 9.54319C3.66506 9.54319 3.75168 9.9917 3.55488 10.1018C3.19001 10.3061 2.46117 9.63752 2.65015 8.41808C2.65015 8.41808 1.10368 10.7454 3.97429 13.2692C3.67113 13.1938 -1.69508 11.7767 0.545489 6.43164C0.545489 6.43164 0.473375 7.55261 1.25745 7.34823C2.08742 7.13189 0.871989 4.02819 2.8899 2.78913C2.88342 2.80353 2.198 4.30399 3.07486 4.39793C3.95616 4.49236 5.02612 1.33755 4.0507 0C4.06778 0.00997856 6.41755 1.41282 6.04897 4.83851C6.04897 4.83851 5.82862 5.70385 6.32435 5.86918C6.32435 5.86918 6.67842 5.93214 6.63903 5.50729C6.57957 4.86456 6.94588 4.02811 7.6304 3.70561C7.6304 3.70561 6.83583 4.68917 8.35419 7.36406C9.87256 10.0389 8.00802 12.6355 5.48263 13.2728L5.48259 13.2725Z"
        fill={outsideColor}
      />
    </svg>
  );
};

CCIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  outsideColor: PropTypes.string,
  insideColor: PropTypes.string,
};

export default CCIcon;
