import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const Workflows = ({
  width = "21",
  height = "21",
  fill = "var(--royal-peacock-blue)",
  active = false,
  hovered = false,
  containerStyles,
}) => {
  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      style={{ paddingBottom: "1px" }}
      {...containerStyles}
    >
      <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.2435 0.34375C7.32566 0.34375 8.20291 1.221 8.20291 2.30316L8.20224 3.28275L12.2451 3.2832C12.6639 1.65542 14.1023 0.436876 15.8389 0.348846L16.0406 0.34375C18.2049 0.34375 19.9594 2.09827 19.9594 4.26259C19.9594 6.08837 18.7109 7.62252 17.021 8.05779L17.0203 12.6738L20.3652 16.0191L16.0406 20.3438L12.6953 16.9987L8.07928 16.9995C7.66014 18.6268 6.22202 19.8448 4.48575 19.9328L4.28406 19.9379C2.11974 19.9379 0.365234 18.1834 0.365234 16.0191C0.365234 14.193 1.6143 12.6586 3.30469 12.2236L3.30423 8.18076L2.32465 8.18143C1.29168 8.18143 0.445405 7.3821 0.370605 6.36824L0.365234 6.222V2.30316C0.365234 1.221 1.24249 0.34375 2.32465 0.34375H6.2435ZM4.28406 14.0597C3.2019 14.0597 2.32465 14.9369 2.32465 16.0191C2.32465 17.1013 3.2019 17.9785 4.28406 17.9785C5.36621 17.9785 6.2435 17.1013 6.2435 16.0191C6.2435 14.9369 5.36621 14.0597 4.28406 14.0597ZM16.0406 14.4663L14.4877 16.0191L16.0406 17.5719L17.5934 16.0191L16.0406 14.4663ZM8.20224 5.24174L8.20291 6.222C8.20291 7.30416 7.32566 8.18143 6.2435 8.18143L5.26324 8.18076L5.26346 12.2236C6.64081 12.578 7.72515 13.6624 8.07953 15.0397L12.6953 15.0388L15.0602 12.6738L15.0612 8.05804C13.6839 7.70367 12.5995 6.61933 12.2451 5.24197L8.20224 5.24174ZM6.2435 2.30316H2.32465V6.222H6.2435V2.30316ZM16.0406 2.30316C14.9584 2.30316 14.0812 3.18043 14.0812 4.26259C14.0812 5.34475 14.9584 6.222 16.0406 6.222C17.1227 6.222 18 5.34475 18 4.26259C18 3.18043 17.1227 2.30316 16.0406 2.30316Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

Workflows.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

export default Workflows;
