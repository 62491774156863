import React from "react";
import PropTypes from "prop-types";

import { Form, Select } from "antd";
import { CaretDownFilled } from "@ant-design/icons";

import styles from "./SelectInput.module.less";

const { Item } = Form;
const { Option } = Select;

const SelectInput = ({
  choices = [],
  onChange = () => null,
  value,
  itemName,
  rules,
  validateTrigger = "onSubmit",
  placeholder = "Select...",
  initialValue,
  mode,
  defaultValue = false,
  disabled = false,
  children,
  optionClassName,
  status = "",
}) => {
  return (
    <div className={styles.selectInput}>
      <Item name={itemName} initialValue={initialValue} rules={rules} validateTrigger={validateTrigger}>
        <Select
          className={styles.SelectInput}
          suffixIcon={<CaretDownFilled className={styles.caretIcon} />}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          mode={mode}
          defaultValue={defaultValue}
          disabled={disabled}
          status={status}
        >
          {choices?.map((option) => (
            <Option
              key={option.key || option.value}
              value={option.value}
              title={option.label}
              className={optionClassName}
            >
              {option.label}
            </Option>
          ))}
          {children}
        </Select>
      </Item>
    </div>
  );
};

SelectInput.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    }),
  ),
  onChange: PropTypes.func,
  itemName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  // form validation rules
  rules: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  validateTrigger: PropTypes.string,
  mode: PropTypes.PropTypes.oneOf(["tags", "multiple"]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  disabled: PropTypes.bool,
  children: PropTypes.node,
  optionClassName: PropTypes.string,
  status: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
};

export default SelectInput;
