import React from "react";
import PropTypes from "prop-types";

const CircleCloseIcon = ({ fill = "var(--botticelli-blue)", color = "var(--white)", width = "13", height = "12" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.576172" width="12" height="12" rx="6" fill={fill} />
      <rect
        x="8.82227"
        y="3.29688"
        width="0.65625"
        height="7"
        rx="0.328125"
        transform="rotate(45 8.82227 3.29688)"
        fill={color}
        stroke={color}
        strokeWidth="0.4"
      />
      <rect
        x="9.28516"
        y="8.24219"
        width="0.65625"
        height="7"
        rx="0.328125"
        transform="rotate(135 9.28516 8.24219)"
        fill={color}
        stroke={color}
        strokeWidth="0.4"
      />
    </svg>
  );
};

CircleCloseIcon.propTypes = {
  fill: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default CircleCloseIcon;
