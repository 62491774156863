import React from "react";
import PropTypes from "prop-types";

const RightCarouselArrow = ({ fill = "var(--silver)", height = "18", width = "18" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.77 11.1897C13.8702 10.3912 13.8702 8.75114 12.7699 7.95255L3.17479 0.988274C1.85264 0.0286438 -9.25344e-07 0.973176 -8.53933e-07 2.60687L-2.45096e-07 16.5354C-1.73685e-07 18.1691 1.85265 19.1137 3.1748 18.154L12.77 11.1897Z"
        fill={fill}
      />
    </svg>
  );
};

RightCarouselArrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default RightCarouselArrow;
