import React from "react";
import PropTypes from "prop-types";

const HomeOwnerIcon = ({
  height = "19",
  width = "18",
  fillColor = "var(--royal-peacock-blue)",
  circleStyle = {},
  viewBox,
}) => {
  const viewBoxValue = viewBox || `0 0 ${width} ${height}`;

  return (
    <svg width={width} height={height} viewBox={viewBoxValue} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9.75" r="9" fill={fillColor} style={circleStyle} />
      <path
        d="M13.0737 9.43578L9.45098 5.8275L9.20816 5.58551C9.15283 5.53074 9.07801 5.5 9.00002 5.5C8.92203 5.5 8.84721 5.53074 8.79188 5.58551L4.9263 9.43578C4.86961 9.49205 4.8248 9.55908 4.79453 9.63289C4.76425 9.70671 4.74913 9.78582 4.75004 9.86556C4.75379 10.1944 5.0285 10.457 5.35852 10.457H5.75698V13.5H12.2431V10.457H12.65C12.8103 10.457 12.9612 10.3944 13.0747 10.2813C13.1305 10.2258 13.1748 10.1599 13.2049 10.0872C13.235 10.0146 13.2503 9.93668 13.25 9.85808C13.25 9.69925 13.1872 9.54883 13.0737 9.43578ZM9.52505 12.8273H8.47498V10.9213H9.52505V12.8273ZM11.568 9.78427V12.8273H10.1251V10.6971C10.1251 10.4906 9.95727 10.3234 9.75007 10.3234H8.24997C8.04276 10.3234 7.87494 10.4906 7.87494 10.6971V12.8273H6.43203V9.78427H5.53197L9.00095 6.33015L9.21753 6.54598L12.469 9.78427H11.568Z"
        fill="white"
      />
    </svg>
  );
};

HomeOwnerIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillColor: PropTypes.string,
  circleStyle: PropTypes.objectOf(PropTypes.string),
  viewBox: PropTypes.string,
};

export default HomeOwnerIcon;
