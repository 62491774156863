import React from "react";
import PropTypes from "prop-types";

const CircleCheck = ({ fill = "white", height = "10", width = "14" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 16.0769C33 24.956 25.6127 32.1538 16.5 32.1538C7.3873 32.1538 0 24.956 0 16.0769C0 7.19788 7.3873 0 16.5 0C25.6127 0 33 7.19788 33 16.0769ZM24.0784 9.30769C24.4232 9.30769 24.7548 9.43851 25.0043 9.67301C25.248 9.91595 25.3846 10.2437 25.3846 10.5852C25.3846 10.9268 25.248 11.2545 25.0043 11.4975C21.0612 14.5713 17.5818 18.1821 14.6708 22.2212C14.5524 22.4121 14.3863 22.5698 14.1883 22.6792C13.9903 22.7887 13.7671 22.8461 13.54 22.8461C13.313 22.8461 13.0898 22.7887 12.8918 22.6792C12.6938 22.5698 12.5276 22.4121 12.4093 22.2212L9.46659 16.6117C8.69313 15.1373 10.9539 13.8338 11.728 15.3094L13.7399 19.1445C16.4717 15.6194 19.6329 12.4384 23.1524 9.67301C23.4019 9.43851 23.7335 9.30769 24.0784 9.30769Z"
        fill={fill}
      />
    </svg>
  );
};

CircleCheck.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default CircleCheck;
