import React from "react";
import PropTypes from "prop-types";

const PowurLogoMobile = ({ height = "44", width = "45" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="10.5332"
        y="13.334"
        width="24.7479"
        height="2.74977"
        rx="0.25"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
      <rect
        x="10.5332"
        y="21.583"
        width="24.7479"
        height="2.74977"
        rx="0.25"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
      <rect
        x="10.5332"
        y="29.833"
        width="24.7479"
        height="2.74977"
        rx="0.25"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle cx="22.904" cy="22.9577" r="8.24777" fill="var(--royal-peacock-blue)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1128 20.972L20.92 18.1648H24.8911L22.905 16.1787L20.9203 18.1634H18.1128V20.972ZM16.125 22.9577L18.1111 24.9438V20.9716L16.125 22.9577ZM22.905 29.7424L24.8911 27.7563H20.9189L22.905 29.7424ZM22.9046 25.7695V22.9594H25.7148L22.9046 25.7695ZM22.9071 22.9587V20.1502L20.0985 22.9587H22.9071ZM20.9213 27.7552H18.1128V24.9451L20.9213 27.7552ZM27.7025 27.7552V24.9451L24.8924 27.7552H27.7025ZM27.6985 20.9716L29.6846 22.9577L27.6985 24.9438V20.9716ZM27.7025 18.1634H24.8924L27.7025 20.972V18.1634Z"
        fill="white"
      />
    </svg>
  );
};

PowurLogoMobile.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default PowurLogoMobile;
