import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import { useSelector, connect, useDispatch } from "react-redux";
import selectors from "rdx/selectors";
import { useHistory, useParams } from "react-router-dom";
import { Tabs } from "antd";
import { actions } from "rdx";
import usePermissions, { useListOfPermissionChecks, permissionTypes } from "hooks/usePermissions";
import pathToRegexp from "path-to-regexp";
import { useWindowSize } from "react-use";
import { DownCaret } from "components/Icons";
import { theme } from "styles/themes";
import { ListItemIcon, ListItemText } from "@mui/material";
import styles from "./TabsControl.module.less";
import NewTabs from "./NewTabs";
import { CheckIcon, TabDropDown, TabDropDownItem } from "./styles";

const TabBar = (props) => {
  const { tabs, basePath, activateAcctBtnClicked, resetActivateAcctBtnClicked, newHeader } = props;
  const history = useHistory();
  const params = useParams();
  const { width } = useWindowSize();
  const mobile = width < 668;
  const dispatch = useDispatch();
  const bannersHeight = useSelector(selectors.selectBannersHeight);
  const activeTabKey = tabs.find((tab) => tab.key.includes(params.tab))?.key;
  const isMiniHeaderActive = useSelector(selectors.selectProjectMiniHeaderActive);
  const tabsAsLeadGenerator = useSelector(selectors.getTabsAsLeadGenerator);

  const { ENTERPRISE_LEAD_GENERATOR } = usePermissions({
    permissionRequests: [permissionTypes.ENTERPRISE_LEAD_GENERATOR],
  });

  const tabPermissions = useListOfPermissionChecks({
    permissionsList: tabs.map(({ permission }) => {
      if (!permission) {
        return true;
      }
      return permission;
    }),
  });

  useEffect(() => {
    if (history.location.pathname !== "/getting-started/account-types/payment") {
      resetActivateAcctBtnClicked();
    }
  }, [history.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const conditionalRenderedTabs = useSelector((state) => {
    return tabs.map((tab) => {
      if (!tab.conditionalRenderSelector) {
        return true;
      }
      if (!selectors[tab.conditionalRenderSelector]) {
        throw new Error(
          `No selector found with name ${tab.conditionalRenderSelector}. Check that the value of "conditionalRenderSelector" in the "${tab.tabKey}" tab corresponds to a valid redux selector which returns a boolean.`,
        );
      }
      return selectors[tab.conditionalRenderSelector](state);
    });
  });

  const LEAD_GEN_KEYS_WHITELIST = [
    "activity-log",
    "pre-sales?status=active",
    "post-sales?status=active",
    "completed",
    "general",
    "reset-password",
    "e-wallet",
    "achievements-and-certifications",
  ];

  const filteredTabs = tabs.filter((t, idx) => {
    if (t.key === "site-survey") {
      return false;
    }

    if (ENTERPRISE_LEAD_GENERATOR || tabsAsLeadGenerator) {
      return LEAD_GEN_KEYS_WHITELIST.includes(t.key);
    }
    return tabPermissions[idx] && conditionalRenderedTabs[idx];
  });

  const parsePathParams = useMemo(() => {
    let pathname = "";
    for (const item of pathToRegexp.parse(basePath)) {
      if (typeof item === "string") {
        pathname = `${pathname}/${item}`;
      } else if (item.name && params[item.name]) {
        pathname = `${pathname}/${params[item.name]}`;
      }
    }
    return pathname;
  }, [basePath, params]);

  const basePadding = 48;

  const tabItems = filteredTabs.map((tabInfo) => {
    const activeTab = params.tab === tabInfo.key && !activateAcctBtnClicked;
    return {
      key: tabInfo.key,
      label: (
        <span
          data-test-id={`${tabInfo.label.replace(/\s/g, "-")}-tab`}
          className={activeTab ? styles.tabLabelActive : styles.tabLabel}
        >
          {tabInfo.label}
        </span>
      ),
    };
  });

  return (
    <div
      className={styles.tabBarContainer}
      style={{
        top: `${basePadding + bannersHeight}px`,
        ...(newHeader && {
          position: "unset",
          background: isMiniHeaderActive ? theme.colors.white : theme.colors["table-edit-mode"],
          display: "flex",
          justifyContent: "center",
          padding: mobile ? "0px 13px" : "0px 40px",
          ...(isMiniHeaderActive && {
            boxShadow: "0px 4px 12px -2px rgba(0, 0, 0, 0.03)",
          }),
        }),
      }}
    >
      {!mobile && !newHeader && (
        <Tabs
          className={styles.tabs}
          activeKey={activateAcctBtnClicked ? null : activeTabKey}
          onTabClick={(key) => {
            dispatch(actions.clearPreferredView());
            history.push(`${parsePathParams}/${key}`);
          }}
          items={tabItems}
        />
      )}
      {newHeader && (
        <NewTabs
          params={params}
          tabs={filteredTabs}
          defaultValue={activateAcctBtnClicked ? null : activeTabKey}
          handleTabSelection={(_, key) => history.push(`${parsePathParams}/${key}`)}
        />
      )}
      {mobile && !newHeader && (
        <TabDropDown
          renderValue={(value) => <>{filteredTabs.find((tabInfo) => tabInfo.key === value)?.label}</>}
          IconComponent={() => (
            <DownCaret
              width="10px"
              fill={theme.colors["darkest-blue"]}
              containerStyles={{ paddingRight: "15px", paddingBottom: "3px" }}
            />
          )}
          onChange={(event) => {
            history.push(`${parsePathParams}/${event.target.value}`);
          }}
          defaultValue={params.tab}
        >
          {filteredTabs &&
            filteredTabs.map((tabInfo) => (
              <TabDropDownItem
                key={tabInfo.key}
                selected={params.tab === tabInfo.key && !activateAcctBtnClicked}
                value={tabInfo.key}
              >
                <ListItemText>{tabInfo.label}</ListItemText>
                <ListItemIcon style={{ justifyContent: "flex-end" }}>
                  {params.tab === tabInfo.key && !activateAcctBtnClicked && <CheckIcon />}
                </ListItemIcon>
              </TabDropDownItem>
            ))}
        </TabDropDown>
      )}
    </div>
  );
};

TabBar.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      permission: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      conditionalRenderSelector: PropTypes.string,
    }),
  ),
  basePath: PropTypes.string,
  activateAcctBtnClicked: PropTypes.bool,
  resetActivateAcctBtnClicked: PropTypes.func,
  newHeader: PropTypes.bool,
};

export default connect(
  getRdxSelectionMapper({
    activateAcctBtnClicked: "selectActivateAcctBtnClicked",
  }),
  getRdxActionMapper(["resetActivateAcctBtnClicked"]),
)(TabBar);
