import React from "react";
import PropTypes from "prop-types";

const RightArrow = ({ fill = "var(--light-navy)", height = "9", width = "10" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.04436 0.191615C4.78881 0.447277 4.78881 0.86179 5.04436 1.11741L7.74145 3.81455H0.654674C0.293073 3.81455 0 4.1077 0 4.46918C0 4.83074 0.293073 5.12382 0.654674 5.12382H7.74444L4.98581 7.88249C4.73027 8.13811 4.73027 8.55266 4.98581 8.80828C5.24151 9.06391 5.65595 9.06391 5.91157 8.80828L9.66468 5.05509C9.68407 5.03571 9.70159 5.01508 9.71794 4.99398C9.87823 4.87484 9.98254 4.68436 9.98254 4.46926C9.98254 4.26076 9.88468 4.07537 9.73274 3.95553C9.72944 3.95195 9.72653 3.9483 9.72307 3.94484L5.96996 0.191732C5.71453 -0.0638911 5.29994 -0.0638912 5.04436 0.191615Z"
        fill={fill}
      />
    </svg>
  );
};

RightArrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default RightArrow;
