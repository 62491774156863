import React from "react";
import PropTypes from "prop-types";

const EyeIconDash = ({ fill = "var(--dark-periwinkle)", height = "16", width = "22" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 16"
      // fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 1.94531C6.52133 1.94531 2.764 4.23731 0 7.94531C2.764 11.6533 6.52133 13.9453 10.6667 13.9453C14.8147 13.9453 18.5693 11.6533 21.3333 7.94531C18.5693 4.23731 14.812 1.94531 10.6667 1.94531ZM10.6667 3.94531C12.876 3.94531 14.6667 5.73598 14.6667 7.94531C14.6667 10.1546 12.876 11.9453 10.6667 11.9453C8.45733 11.9453 6.66667 10.1546 6.66667 7.94531C6.66667 5.73598 8.45733 3.94531 10.6667 3.94531Z"
        fill={fill}
      />
      <rect
        x="16.4336"
        y="0.277268"
        width="2.6875"
        height="19"
        rx="1.34375"
        transform="rotate(45 16.4336 0.277268)"
        fill={fill}
        stroke="white"
      />
    </svg>
  );
};

EyeIconDash.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default EyeIconDash;
