import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const ArchiveIcon = ({ fill = "white", width = "23", height = "17", containerStyles, active, hovered }) => {
  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      {...containerStyles}
    >
      <svg width={width} height={height} viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.62347 9.96886C5.40185 9.86986 5.13758 9.94304 5.02898 10.1601C4.61523 10.9871 4.61523 11.8227 4.61523 11.8227C4.61523 11.8227 4.61523 11.8227 4.61523 11.8227V12.2227C4.61523 12.4988 4.83943 12.721 5.11502 12.7384C7.46415 12.8865 9.53599 14.0714 10.8759 15.8384C11.1031 16.138 11.5774 16.138 11.8046 15.8384C13.1455 14.0704 15.221 12.8851 17.5904 12.7381C17.866 12.721 18.0902 12.4988 18.0902 12.2227V11.8227C18.0902 11.1202 17.9379 10.5701 17.7047 10.1366C17.5936 9.93007 17.3376 9.86527 17.1233 9.96026L11.5436 12.4325C11.4141 12.4899 11.2664 12.4897 11.1371 12.4319L5.62347 9.96886ZM22.3304 6.65196C22.7279 6.47747 22.7295 5.91421 22.333 5.73747L11.5688 0.938427C11.4392 0.880654 11.2912 0.880654 11.1616 0.938427L0.389541 5.74098C-0.00566028 5.91718 -0.005661 6.47813 0.389541 6.65432L11.162 11.4571C11.2914 11.5147 11.4391 11.5148 11.5686 11.4573L19.8373 7.78487C20.1679 7.63802 20.5402 7.88005 20.5402 8.24182V9.24343C20.5402 9.46765 20.3859 9.6573 20.1909 9.76796C19.7466 10.0201 19.4402 10.4855 19.4402 11.0477C19.4402 11.5343 19.6675 11.9602 20.0278 12.2312C20.2106 12.3688 20.3429 12.5813 20.311 12.8079L19.9455 15.4029C19.9031 15.7038 20.1367 15.9727 20.4406 15.9727H21.4149C21.7188 15.9727 21.9524 15.7038 21.91 15.4029L21.5445 12.8079C21.5126 12.5813 21.6449 12.3688 21.8277 12.2312C22.1879 11.9602 22.4152 11.5343 22.4152 11.0477C22.4152 10.5052 22.1085 10.0274 21.6637 9.7698C21.4697 9.65743 21.3152 9.46765 21.3152 9.24343V7.42421C21.3152 7.22579 21.4326 7.04615 21.6142 6.96638L22.3304 6.65196Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

ArchiveIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

export default ArchiveIcon;
