import React from "react";
import PropTypes from "prop-types";

const CircleCheckFilled = ({ fill = "var(--botticelli-blue)", height = "43", width = "42" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_4113_7323)">
        <rect x="1" y="1" width="40" height="40" rx="20" fill={fill} />
      </g>
      <path
        d="M30.048 11.1532C29.0347 10.3193 27.5601 10.4916 26.7536 11.537L17.6044 25.7583L13.6107 21.6345C12.7891 20.7835 11.4991 20.7406 10.7293 21.5345C9.96087 22.3277 10.0031 23.6603 10.8254 24.5107L16.8477 30.7338C17.5518 31.4598 18.5782 31.5577 19.3487 31.0854C19.6807 30.9325 19.9851 30.7088 20.2278 30.3951L30.418 14.5546C31.2259 13.5099 31.0578 11.9879 30.048 11.1532Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_4113_7323"
          x="0"
          y="0.5"
          width="42"
          height="42"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4113_7323" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4113_7323" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

CircleCheckFilled.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default CircleCheckFilled;
