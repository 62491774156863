import { PreSalesProjectT, RecordTypesT } from "rdx/modules/projectPreSales/stateTypes";
import { LeadT } from "types/Lead";

const formatPreSalesLead = (leadData: LeadT): Omit<PreSalesProjectT, "closeAt" | "hasCurrentOffer"> => {
  const recordTypes = ["new", "pre_sales"] as unknown as RecordTypesT[];
  return {
    id: `${leadData.id}_${leadData.projectId}`,
    leadId: leadData.id,
    leadOwnerId: leadData.owner.id,
    leadFirstName: leadData.firstName,
    leadLastName: leadData.lastName,
    leadEmail: leadData.email,
    leadPhone: leadData.phone,
    leadKind: leadData.kind,
    leadReferrerId: null,
    leadEnterpriseId: 0,
    siteId: leadData.siteId,
    addressId: leadData.addressId,
    addressCity: leadData.address.city,
    addressState: leadData.address.state,
    projectId: leadData.projectId,
    projectSellerId: null,
    projectStageScore: 100,
    leadQualificationPercent: leadData.qualificationPercent,
    coalescedUpdatedAt: leadData.updatedAt,
    recordTypes,
    projectUsers: [{ ...leadData.owner, role: "Lead Owner" }],
    tickets: 0,
    activities: [],
    projectMentorId: null,
    projectManagerId: null,
    tpId: null,
    tpCreatedAt: null,
    tpUpdatedAt: null,
    tpLatestStageAt: null,
    projectStageId: null,
    projectStageKind: null,
    projectStageMilestoneName: null,
    projectStageName: null,
    passedStageNames: leadData.passedStageNames,
    upcomingStageNames: leadData.upcomingStageNames,
    roofingNonRequestableReason: null,
    proposalNonRequestableReason: null,
    visionProposalNonRequestableReason: null,
    isUserLeadGen: null,
    isValidServiceArea: leadData.isValidServiceArea,
    hasProposals: false,
    isFastPayPreferred: false,
    canRequestAurora: false,
    lightreachProposalNonrequestableReason: null,
    soloProposalNonrequestableReason: null,
    auroraNonRequestableReason: null,
    homeowner: {
      leadId: leadData.id,
      leadFirstName: leadData.firstName,
      leadLastName: leadData.lastName,
      leadEmail: leadData.email,
      leadPhone: leadData.phone,
      address: {
        addressCity: leadData.address.city,
        addressState: leadData.address.state,
        addressStreet: leadData.address.street,
        addressZip: leadData.address.zip,
      },
    },
  };
};

export default formatPreSalesLead;
