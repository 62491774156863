import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const Compensation = ({
  fill = "var(--royal-peacock-blue)",
  height = "22",
  width = "21",
  active = false,
  hovered = false,
}) => {
  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      style={{
        padding: "0 0px 1px 1px",
      }}
    >
      <svg width={width} height={height} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.02129 7.60938H7.49219C7.21605 7.60938 6.99219 7.83323 6.99219 8.10938V15.5397C6.99219 15.8159 7.21605 16.0397 7.49219 16.0397H9.02129C9.29743 16.0397 9.52129 15.8159 9.52129 15.5397V8.10938C9.52129 7.83323 9.29743 7.60938 9.02129 7.60938Z"
          fill={fill}
        />
        <path
          d="M17.4529 7.60938H15.9238C15.6477 7.60938 15.4238 7.83323 15.4238 8.10938V15.5397C15.4238 15.8159 15.6477 16.0397 15.9238 16.0397H17.4529C17.7291 16.0397 17.9529 15.8159 17.9529 15.5397V8.10938C17.9529 7.83323 17.7291 7.60938 17.4529 7.60938Z"
          fill={fill}
        />
        <path
          d="M4.79863 7.60938H3.26953C2.99339 7.60938 2.76953 7.83323 2.76953 8.10938V15.5397C2.76953 15.8159 2.99339 16.0397 3.26953 16.0397H4.79863C5.07478 16.0397 5.29863 15.8159 5.29863 15.5397V8.10938C5.29863 7.83323 5.07478 7.60938 4.79863 7.60938Z"
          fill={fill}
        />
        <path
          d="M13.24 7.60938H11.7109C11.4348 7.60938 11.2109 7.83323 11.2109 8.10938V15.5397C11.2109 15.8159 11.4348 16.0397 11.7109 16.0397H13.24C13.5162 16.0397 13.74 15.8159 13.74 15.5397V8.10938C13.74 7.83323 13.5162 7.60938 13.24 7.60938Z"
          fill={fill}
        />
        <path
          d="M19.2163 18.9859C19.2163 18.9743 19.2069 18.9648 19.1952 18.9648H1.5126H0.641367C0.451981 18.9648 0.265305 19.072 0.251831 19.2609C0.248047 19.314 0.248047 19.3686 0.248047 19.4285V20.6146C0.248047 20.8907 0.471905 21.1146 0.748047 21.1146H19.9809C20.257 21.1146 20.4809 20.8907 20.4809 20.6146V19.4285C20.4809 19.4038 20.48 19.3799 20.4783 19.3569C20.4626 19.1422 20.2514 19.007 20.0362 19.007H19.2374C19.2257 19.007 19.2163 18.9976 19.2163 18.9859V18.9859Z"
          fill={fill}
        />
        <path
          d="M1.50781 17.3066V17.6496C1.50781 17.9258 1.73167 18.1496 2.00781 18.1496H18.7115C18.9877 18.1496 19.2115 17.9258 19.2115 17.6496V17.3066C19.2115 17.2654 19.209 17.2243 19.2046 17.1831C19.1802 16.955 18.9663 16.8008 18.7368 16.8008H1.98251C1.75307 16.8008 1.53919 16.955 1.51474 17.1831C1.51033 17.2243 1.50781 17.2654 1.50781 17.3066Z"
          fill={fill}
        />
        <path
          d="M20.2721 5.72199L10.5672 0.848536C10.5458 0.837789 10.5237 0.828266 10.5006 0.821962C10.3969 0.793683 10.2933 0.793721 10.1897 0.822077C10.1668 0.82832 10.145 0.837705 10.1239 0.848286L0.460759 5.67984C0.334303 5.76414 0.25 5.93275 0.25 6.10136V6.36009C0.25 6.63623 0.473858 6.86009 0.75 6.86009H19.9828C20.259 6.86009 20.4828 6.63623 20.4828 6.36009V6.14351C20.4828 5.93275 20.3985 5.8063 20.2721 5.72199ZM10.3664 6.35427C9.43907 6.35427 8.68034 5.59554 8.68034 4.6682C8.68034 3.74086 9.43907 2.98213 10.3664 2.98213C11.2937 2.98213 12.0525 3.74086 12.0525 4.6682C12.0525 5.59554 11.2937 6.35427 10.3664 6.35427Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

Compensation.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

export default Compensation;
