import React, { useState } from "react";
import PropTypes from "prop-types";

import { Form } from "antd";

import { css } from "@emotion/react";

import MagnifyingGlassIcon from "components/Icons/MagnifyingGlassIcon";
import CloseIconPlain from "components/Icons/CloseIconPlain";
import { CancelIconFilled } from "components/Icons";

import { theme } from "styles/themes";
import styles from "../SearchFilterCSV/SearchFilterCSV.module.less";
import { StyledLine, StyledLineContainer, StyledSearchInput } from "../SearchFilterCSV/styles";

/*  newSearchBar is a temp prop to use new styles for the search input (currently only for admin projects view) */

const SearchFilterPagination = ({
  mobile = false,
  smallSearchOpen = false,
  setSmallSearchOpen,
  setSearch,
  placeholder = "Search",
  debouncedSearch,
  newSearchBar = false,
  search,
}) => {
  const [fieldFocus, setFieldFocus] = useState(false);

  const handleIconsColrs = (params = { isCloseIcon: false }) => {
    if (newSearchBar && search?.length > 0 && !fieldFocus) return theme.colors.white;
    if (newSearchBar && params.isCloseIcon) return theme.colors["cotton-boll-highlighted"];
    if (newSearchBar && !params.isCloseIcon) return theme.colors["blue-ribbon"];
    return theme.colors["dark-blue"];
  };

  return mobile ? (
    <div
      className={smallSearchOpen ? styles.smallSearchOpen : styles.smallSearch}
      onClick={() => setSmallSearchOpen(!smallSearchOpen)}
      onKeyDown={() => setSmallSearchOpen(!smallSearchOpen)}
      tabIndex={0}
      role="button"
    >
      {smallSearchOpen ? <CloseIconPlain /> : <MagnifyingGlassIcon />}
    </div>
  ) : (
    <Form.Item name="search" style={{ marginBottom: 0 }}>
      <StyledSearchInput
        onFocus={() => {
          setFieldFocus(true);
        }}
        onBlur={() => {
          setFieldFocus(false);
        }}
        newSearchBar={newSearchBar}
        notEmpty={search?.length > 0}
        allowClear={{
          clearIcon: (
            <div
              css={css`
                margin-top: 4px;
                margin-right: -2px;
              `}
            >
              <CancelIconFilled
                height="12px"
                width="12px"
                backfill={handleIconsColrs({ isCloseIcon: true })}
                fill={
                  newSearchBar && search?.length > 0 && !fieldFocus ? theme.colors["blue-ribbon"] : theme.colors.white
                }
              />
            </div>
          ),
        }}
        className={debouncedSearch ? styles.searchActive : styles.search}
        placeholder={placeholder}
        onChange={(e) => {
          const out = e.target.value.replace(/[^a-z0-9]/gi, " ");
          setSearch(out);
        }}
        prefix={
          <StyledLineContainer>
            <MagnifyingGlassIcon fill={handleIconsColrs()} />
            {!newSearchBar && <StyledLine />}
          </StyledLineContainer>
        }
      />
    </Form.Item>
  );
};

SearchFilterPagination.propTypes = {
  mobile: PropTypes.bool,
  smallSearchOpen: PropTypes.bool,
  newSearchBar: PropTypes.bool,
  setSmallSearchOpen: PropTypes.func,
  setSearch: PropTypes.func,
  placeholder: PropTypes.string,
  search: PropTypes.string,
  debouncedSearch: PropTypes.string,
};

export default SearchFilterPagination;
