import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import selectors from "rdx/selectors";

import { CloseOutlined } from "@ant-design/icons";
import { Drawer, Layout } from "antd";

import AirbrakeErrorBoundary from "components/ErrorBoundary";
import ErrorFallback from "components/ErrorBoundary/components/Drawer";
import DetailHeader from "./DetailsHeader";
import styles from "./Drawer.module.less";

const { Header, Content, Footer } = Layout;

const DrawerContainer = ({
  drawerClassName = "",
  visible,
  setVisible,
  showDrawerMask = true,
  width,
  drawerProps = {},
  headerText = "",
  detailHeaderContent = false,
  headerContent,
  footerContent,
  contentStyle = {},
  footerStyle = {},
  headerStyle,
  zIndex = 1002,
  children,
  onClose = () => null,
  backgroundLinesLink,
}) => {
  const bannersHeight = useSelector(selectors.selectBannersHeight);

  const renderHeader = () => {
    if (headerContent) return headerContent;
    return (
      <>
        <div className={styles.iconContainer}>
          <CloseOutlined onClick={() => setVisible(false)} />
        </div>
        {detailHeaderContent ? (
          <DetailHeader content={detailHeaderContent} />
        ) : (
          <div className={styles.title}>{headerText}</div>
        )}
      </>
    );
  };

  useEffect(() => {
    const closeOnKeydown = (e) => {
      if (e.keyCode === 27) {
        setVisible(false);
      }
    };

    if (visible) {
      window.addEventListener("keydown", closeOnKeydown);
    } else {
      window.removeEventListener("keydown", closeOnKeydown);
    }

    return () => window.removeEventListener("keydown", closeOnKeydown);
  }, [visible, setVisible]);

  const closeDrawer = () => {
    setVisible(false);
    onClose();
  };

  const backgroundLines = () => {
    if (backgroundLinesLink) {
      return {
        backgroundImage: backgroundLinesLink,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top right",
      };
    }
    return {};
  };

  return (
    <Drawer
      className={[styles.drawerContainer, drawerClassName].join(" ")}
      closable={false}
      mask={!!showDrawerMask}
      maskStyle={{ backgroundColor: "var(--mask-blue-a75)" }}
      destroyOnClose
      placement="right"
      open={visible}
      onClose={closeDrawer}
      width={Math.min(window.innerWidth, width || 600)}
      zIndex={zIndex}
      bodyStyle={{
        padding: 0,
      }}
      {...drawerProps}
    >
      <Layout className={styles.drawerLayout} style={{ paddingTop: bannersHeight }}>
        <Header className={styles.header} style={{ ...headerStyle }}>
          {renderHeader()}
        </Header>
        <Content className={styles.content} style={{ ...backgroundLines(), ...contentStyle }}>
          <AirbrakeErrorBoundary fallbackComponent={<ErrorFallback closeDrawer={() => setVisible(false)} />}>
            {children}
          </AirbrakeErrorBoundary>
        </Content>
        {footerContent && (
          <Footer style={{ ...footerStyle }} className={styles.drawerFooter}>
            {footerContent}
          </Footer>
        )}
      </Layout>
    </Drawer>
  );
};

DrawerContainer.propTypes = {
  drawerClassName: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  showDrawerMask: PropTypes.bool,
  /* pass through props to ant design components */
  drawerProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  width: PropTypes.number,
  headerText: PropTypes.string,
  detailHeaderContent: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      titleText: PropTypes.string,
      detailText: PropTypes.string,
    }),
  ]),
  headerContent: PropTypes.node,
  footerContent: PropTypes.node,
  contentStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  footerStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  headerStyle: PropTypes.objectOf(PropTypes.string),
  zIndex: PropTypes.number,
  onClose: PropTypes.func,
  backgroundLinesLink: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default DrawerContainer;
