import React from "react";
import PropTypes from "prop-types";

const PowurLogo = ({ height = "29", width = "28", fill = "white" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.09961 10.7082V4.91016H9.89768L4.09961 10.7082Z" fill={fill} />
      <path d="M4.10014 18.9112L0 14.8111L4.10014 10.7109V18.9112Z" fill={fill} />
      <path d="M9.89648 4.91264L13.9966 0.8125L18.0968 4.91264H9.89648Z" fill={fill} />
      <path d="M18.0968 24.7148L13.9966 28.815L9.89648 24.7148H18.0968Z" fill={fill} />
      <path d="M13.998 20.6099V14.8086H19.7993L13.998 20.6099Z" fill={fill} />
      <path d="M13.9973 9.01172V14.8098H8.19922L13.9973 9.01172Z" fill={fill} />
      <path d="M9.89768 24.7114H4.09961V18.9102L9.89768 24.7114Z" fill={fill} />
      <path d="M23.8989 18.9102V24.7114H18.0977L23.8989 18.9102Z" fill={fill} />
      <path d="M23.8984 10.7109L27.9986 14.8111L23.8984 18.9112V10.7109Z" fill={fill} />
      <path d="M18.0977 4.91016H23.8989V10.7082L18.0977 4.91016Z" fill={fill} />
    </svg>
  );
};

PowurLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default PowurLogo;
