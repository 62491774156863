import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const Communications = ({
  fill = "var(--royal-peacock-blue)",
  height = "21",
  width = "20",
  active = false,
  hovered = false,
  containerStyles,
}) => {
  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      {...containerStyles}
    >
      <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.79901 0.617188C1.01265 0.617188 0.365234 1.26441 0.365234 2.05096V11.845C0.365234 12.6315 1.01265 13.2788 1.79901 13.2788H2.81286V15.7619C2.81284 15.8541 2.84088 15.9442 2.89324 16.0201C2.94559 16.096 3.0198 16.1542 3.10601 16.1869C3.19222 16.2197 3.28635 16.2254 3.3759 16.2034C3.46546 16.1814 3.5462 16.1327 3.60742 16.0637L6.08256 13.2788H15.5055C16.2919 13.2788 16.9384 12.631 16.9384 11.845V2.05096C16.9384 1.26491 16.2919 0.617188 15.5055 0.617188H1.79901ZM3.54705 3.91353H10.8198C10.9403 3.91353 11.0559 3.96142 11.1412 4.04666C11.2264 4.13191 11.2743 4.24752 11.2743 4.36808C11.2743 4.48863 11.2264 4.60424 11.1412 4.68949C11.0559 4.77473 10.9403 4.82262 10.8198 4.82262H3.54705C3.4265 4.82262 3.31088 4.77473 3.22564 4.68949C3.1404 4.60424 3.09251 4.48863 3.09251 4.36808C3.09251 4.24752 3.1404 4.13191 3.22564 4.04666C3.31088 3.96142 3.4265 3.91353 3.54705 3.91353V3.91353ZM3.54705 6.6408H13.5471C13.6676 6.6408 13.7832 6.68869 13.8685 6.77394C13.9537 6.85918 14.0016 6.9748 14.0016 7.09535C14.0016 7.2159 13.9537 7.33152 13.8685 7.41676C13.7832 7.502 13.6676 7.54989 13.5471 7.54989H3.54705C3.4265 7.54989 3.31088 7.502 3.22564 7.41676C3.1404 7.33152 3.09251 7.2159 3.09251 7.09535C3.09251 6.9748 3.1404 6.85918 3.22564 6.77394C3.31088 6.68869 3.4265 6.6408 3.54705 6.6408V6.6408ZM3.54705 9.36808H10.8198C10.9403 9.36808 11.0559 9.41596 11.1412 9.50121C11.2264 9.58645 11.2743 9.70207 11.2743 9.82262C11.2743 9.94317 11.2264 10.0588 11.1412 10.144C11.0559 10.2293 10.9403 10.2772 10.8198 10.2772H3.54705C3.4265 10.2772 3.31088 10.2293 3.22564 10.144C3.1404 10.0588 3.09251 9.94317 3.09251 9.82262C3.09251 9.70207 3.1404 9.58645 3.22564 9.50121C3.31088 9.41596 3.4265 9.36808 3.54705 9.36808V9.36808Z"
          fill={fill}
        />
        <path
          d="M18.0929 5.27344C17.9723 5.27344 17.8567 5.32133 17.7714 5.40657C17.6862 5.49181 17.6383 5.60743 17.6383 5.72798C17.6383 5.84854 17.6862 5.96415 17.7714 6.0494C17.8567 6.13464 17.9723 6.18253 18.0929 6.18253H19.0543C19.285 6.18253 19.4565 6.35424 19.4565 6.58469V15.1491C19.4565 15.3796 19.285 15.5513 19.0543 15.5513H17.7697C17.6492 15.5513 17.5336 15.5992 17.4483 15.6845C17.3631 15.7697 17.3152 15.8853 17.3152 16.0059V17.3793L15.8246 15.7031C15.7818 15.6553 15.7294 15.617 15.6708 15.5908C15.6122 15.5646 15.5487 15.5512 15.4846 15.5513H7.06126C6.83057 15.5513 6.65909 15.3796 6.65909 15.1491V14.8242C6.65909 14.7037 6.6112 14.588 6.52596 14.5028C6.44071 14.4176 6.3251 14.3697 6.20455 14.3697C6.08399 14.3697 5.96838 14.4176 5.88313 14.5028C5.79789 14.588 5.75 14.7037 5.75 14.8242V15.1491C5.75 15.8676 6.34278 16.4604 7.06126 16.4604H15.2804L17.4297 18.877C17.4909 18.9459 17.5717 18.9946 17.6612 19.0166C17.7508 19.0386 17.8449 19.0329 17.9311 19.0001C18.0173 18.9674 18.0915 18.9092 18.1439 18.8333C18.1962 18.7574 18.2243 18.6673 18.2243 18.5751V16.4604H19.0543C19.7728 16.4604 20.3656 15.8676 20.3656 15.1491V6.58469C20.3656 5.86621 19.7728 5.27344 19.0543 5.27344H18.0929Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

Communications.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

export default Communications;
