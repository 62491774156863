import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import _ from "lodash";
import { keysToCamelCase, destructureObjArray } from "lib/helpers";
import { isUndefined } from "types/guards";
import messages from "../messages";

function* updateUser(action) {
  const {
    userId,
    qualifiedUntil,
    vanityName,
    sellerForceRank,
    sellerForceRankExpiration,
    authFormat,
    attendedPowurOn,
    external,
    powurEarningsCap,
    ineligibleForFastPay,
    ineligibleToCloseFastPay,
  } = action.payload;

  const params = {
    qualified_until: qualifiedUntil,
    vanity_name: vanityName,
    level: sellerForceRank,
    expiration: sellerForceRankExpiration,
    auth_format: authFormat,
    attended_powur_on: attendedPowurOn,
    ineligible_for_fast_pay: ineligibleForFastPay,
    ineligible_to_close_fast_pay: ineligibleToCloseFastPay,
    external,
  };

  if (!isUndefined(powurEarningsCap)) {
    params.powur_earnings_cap = powurEarningsCap;
  }

  const { success, data, error } = yield* makeRequest.patch(`/users/${userId}`, { ...params });
  if (success && data) {
    const user = keysToCamelCase(_.clone(data.body));
    user.metrics = destructureObjArray(data.body.metrics);
    user.upline = user.upline.map((u) => keysToCamelCase(u));
    user.managerOptions = user.managerOptions.map((u) => keysToCamelCase(u));

    yield all([
      put(actions.setUserDetails(user)),
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_USER_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_USER,
    });
  }
  return null;
}

export default updateUser;
