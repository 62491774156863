import React from "react";
import PropTypes from "prop-types";

const ArchiveIcon = ({ mainColor = "white", height = "16", width = "16", containerStyles }) => {
  return (
    <div style={{ paddingTop: "3px", ...containerStyles }}>
      <svg width={width} height={height} viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.61812 5.99078L0.8 7.96346L0.800001 3.91948L0.229278 3.5L0.229278 2.67236L2.45741 3.29998L7.54237 3.29998L9.77051 2.67236L9.77045 3.46875L9.2 3.89222L9.2 7.96346L6.37736 5.98761L5.70032 6.49021L9.21327 8.94927C9.54466 9.18125 10 8.94417 10 8.53966L10 2.99999C10 2.72384 9.77614 2.49998 9.5 2.49998L0.500001 2.49998C0.223858 2.49998 5.52221e-07 2.72384 5.2808e-07 2.99998L4.37874e-08 8.53966C8.42392e-09 8.94417 0.455343 9.18124 0.786732 8.94927L4.29862 6.49095L3.61812 5.99078Z"
          fill={mainColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.51622 6.15476L9.5 3.36611L9.5 3L8.78675 2.88887L5.05745 5.49937C5.02302 5.52347 4.97719 5.52347 4.94276 5.49937L1.21347 2.88887L0.852521 2.85547L0.364558 3.04668L0.364558 3.27115L4.48399 6.15476C4.79388 6.37168 5.20634 6.37168 5.51622 6.15476Z"
          fill={mainColor}
        />
        <mask id="path-3-inside-1" fill="white">
          <rect y="2.5" width="10" height="7" rx="0.5" />
        </mask>
        <rect y="2.5" width="10" height="7" rx="0.5" stroke="white" strokeWidth="1.6" mask="url(#path-3-inside-1)" />
        <circle cx="9.5" cy="3" r="2" fill="white" stroke="var(--royal-peacock-blue)" strokeWidth="0.5" />
      </svg>
    </div>
  );
};

ArchiveIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  mainColor: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

export default ArchiveIcon;
