import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const init = {
  loginErrors: {
    username: {},
    password: {},
  },
  logoutErrors: {},
  resetPasswordErrors: {},
  newPasswordErrors: {},
  authorizationUri: "",
  termsOfServiceModalVisible: false,
  latestTermsOfService: null,
};

export default {
  auth: createReducer(init, {
    [types.SET_LOGIN_ERRORS](state, action) {
      return {
        ...state,
        loginErrors: {
          ...state.loginErrors,
          ...action.payload,
        },
      };
    },

    [types.SET_LOGOUT_ERRORS](state, action) {
      return {
        ...state,
        logoutErrors: {
          ...action.payload,
        },
      };
    },

    [types.CLEAR_LOGIN_ERRORS](state) {
      return {
        ...state,
        loginErrors: init.loginErrors,
      };
    },

    [types.SET_AUTH_URI](state, action) {
      return {
        ...state,
        authorizationUri: action.payload.authorizationUri,
      };
    },

    [types.SET_RESET_PASSWORD_ERRORS](state, action) {
      return {
        ...state,
        resetPasswordErrors: { ...action.payload },
      };
    },

    [types.CLEAR_RESET_PASSWORD_ERRORS](state) {
      return {
        ...state,
        resetPasswordErrors: init.resetPasswordErrors,
      };
    },

    [types.SET_NEW_PASSWORD_ERRORS](state, action) {
      return {
        ...state,
        newPasswordErrors: {
          ...action.payload,
        },
      };
    },

    [types.CLEAR_NEW_PASSWORD_ERRORS](state) {
      return {
        ...state,
        newPasswordErrors: init.newPasswordErrors,
      };
    },

    [types.SET_LATEST_TOS](state, action) {
      return {
        ...state,
        latestTermsOfService: action.payload,
      };
    },

    [types.SET_TERMS_OF_SERVICE_MODAL_VISIBLE](state, action) {
      return {
        ...state,
        termsOfServiceModalVisible: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
